export class MinPerKilometer {
  static fromMeterPerHour(meterPerHour: number): string {
    const kmPerHour = meterPerHour / 1000;
    const totalSeconds = meterPerHour === 0 ? 0 : Math.round(3600 / kmPerHour);

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  }

  static toMeterPerHour(pace: string): number {
    const [min, sec] = pace.padEnd(4, "0").split(":").map(Number);
    const secAsMin = sec / 60;
    const kmMin = 1 / (min + secAsMin);

    if (!isFinite(kmMin)) {
      return 0;
    }

    const meterMin = kmMin * 1000;
    return meterMin * 60;
  }
}
