export function computeSpeed(
  timeInSeconds: number,
  distanceInMeter: number,
): number {
  const speedInSeconds = distanceInMeter / timeInSeconds;
  if (!isFinite(speedInSeconds)) {
    return 0;
  }

  return speedInSeconds * 3600;
}
