import { AngleType } from "./GeoJsonSegmenter";

export function getPercentColor(angle: number): string | null {
  if (angle >= AngleType.LOW) {
    return angle >= AngleType.HIGH
      ? "red"
      : angle >= AngleType.MEDIUM
        ? "#F98925"
        : "#F6BF2A";
  }
  return null;
}
