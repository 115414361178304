import React, { createContext, useContext, useState } from "react";
import { Image, ImageStyle, useWindowDimensions, View } from "react-native";
import Onboarding from "react-native-onboarding-swiper";
import { translate } from "swunitch-i18n";
import { PRIMARY_SEMI_BOLD_FONT } from "../theme/view-style";
import { useBrandTheme } from "../theme/use-brand-theme";
import { palette } from "../theme";

const OnboardingContext = createContext<{
  launch: () => void;
}>({
  launch: () => null,
});

export const OnboardingProvider = ({ children }) => {
  const layout = useWindowDimensions();
  const [displayOnboarding, setDisplayOnboarding] = useState(false);
  const theme = useBrandTheme();

  const launch = () => {
    setDisplayOnboarding(true);
  };

  const style: ImageStyle = {
    width: layout.width / 1.3,
    height: layout.width / 1.3,
    resizeMode: "contain",
  };
  return (
    <OnboardingContext.Provider
      value={{
        launch,
      }}
    >
      {children}

      {displayOnboarding && (
        <View style={{ height: layout.height }}>
          <Onboarding
            onSkip={() => setDisplayOnboarding(false)}
            onDone={() => setDisplayOnboarding(false)}
            skipLabel={translate("common.skip")}
            nextLabel={translate("common.next")}
            showSkip={false}
            titleStyles={PRIMARY_SEMI_BOLD_FONT}
            pages={[
              {
                backgroundColor: palette.secondary500,
                image: (
                  <Image
                    source={require("../../assets/images/tuto/graph.png")}
                    style={style}
                  />
                ),
                title: translate("landing.tile2"),
                subtitle: translate("landing.tile2Description"),
              },
              {
                backgroundColor: palette.secondary400,
                image: (
                  <Image
                    source={require("../../assets/images/tuto/roadbook.png")}
                    style={style}
                  />
                ),
                title: translate("landing.tile4"),
                subtitle: translate("landing.tile4Description"),
              },
              {
                backgroundColor: palette.secondary300,
                image: (
                  <Image
                    source={require("../../assets/images/tuto/race.png")}
                    style={style}
                  />
                ),
                title: translate("landing.tile1"),
                subtitle: translate("landing.tile1Description"),
              },
              {
                backgroundColor: theme.colors.primary,
                image: (
                  <Image
                    source={require("../../assets/images/tuto/distances.png")}
                    style={style}
                  />
                ),
                title: translate("landing.tile3"),
                subtitle: translate("landing.tile3Description"),
              },
            ]}
          />
        </View>
      )}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  return useContext(OnboardingContext);
};
