import React, { useMemo } from "react";
import { View } from "react-native";
import { observer } from "mobx-react-lite";
import { AppDarkTheme, palette, spacing } from "../../theme";
import { Race } from "../../models/race/race";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { FontAwesome } from "@expo/vector-icons";
import { Text } from "../Text";
import { CONTAINER } from "../../theme/view-style";
import { useOrientation } from "../../utils/orientation-style";
import { useStores } from "../../models";
import { getDay, getHour, getMonth, getYear } from "../../utils/formatDate";
import { Tag } from "../Tag";
import { translate } from "swunitch-i18n";

export const RaceTitle = observer(function RaceTitle(props: { race: Race }) {
  const { race } = props;
  const theme = useBrandTheme();
  const { colors } = theme;
  const { globalStore } = useStores();
  const deviceOrientation = useOrientation();
  const date = useMemo(() => {
    if (!race.date) {
      return null;
    }
    const month = getMonth(race.date);
    const monthShort = month.slice(0, month.length < 5 ? 4 : 3);
    const day = getDay(race.date);
    const year = getYear(race.date);

    return { month: monthShort, day, year };
  }, [race.id]);

  return (
    <View style={CONTAINER(deviceOrientation)}>
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            flexDirection: "row",
            width: "90%",
          }}
        >
          <View
            style={{
              alignItems: "center",
              marginRight: spacing.extraSmall,
            }}
          >
            {date ? (
              <View
                style={{
                  borderRadius: 5,
                  overflow: "hidden",
                  width: 40,
                  borderColor: colors.primary,
                  borderWidth: 1,
                }}
              >
                <Text
                  preset="bold"
                  size="xxs"
                  style={{
                    fontSize: 10,
                    backgroundColor: colors.primary,
                    textTransform: "capitalize",
                    color: AppDarkTheme.colors.text,
                    textAlign: "center",
                    lineHeight: 12,
                  }}
                >
                  {date.month} {date.year}
                </Text>
                <Text
                  style={{
                    backgroundColor: AppDarkTheme.colors.text,
                    textAlign: "center",
                    color: palette.neutral900,
                  }}
                >
                  {date.day}
                </Text>
              </View>
            ) : null}

            <Text
              size="xxs"
              style={{
                color: colors.textDim,
              }}
            >
              {getHour(race.date)}
            </Text>
          </View>
          <View
            style={{
              flexShrink: 1,
            }}
          >
            <Text preset="heading">{race.title}</Text>
            <View style={{ flexDirection: "row", gap: spacing.small }}>
              <Tag>
                <Text size="xxs" style={{ color: AppDarkTheme.colors.text }}>
                  {translate(("sports." + race.type) as "sports.run")}
                </Text>
              </Tag>
            </View>
          </View>
        </View>

        <View
          style={{
            width: "10%",
            alignItems: "flex-end",
          }}
        >
          <FontAwesome
            name={globalStore.isFavoriteRace(race.id) ? "heart" : "heart-o"}
            size={25}
            color={theme.colors.primary}
            onPress={() => globalStore.toggleFavoriteRace(race.id)}
            style={{ padding: spacing.micro }}
          />
        </View>
      </View>
    </View>
  );
});
