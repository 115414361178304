import MaskInput from "react-native-mask-input";
import React, { useCallback, useEffect, useState } from "react";
import {
  NativeSyntheticEvent,
  Platform,
  TextInputFocusEventData,
  TextStyle,
} from "react-native";
import { Mask } from "react-native-mask-input/src/formatWithMask.types";
import { selectTextOnFocus } from "./selectTextOnFocus";
import { useBottomSheetInternal } from "@gorhom/bottom-sheet";

interface ConverterProps {
  formattedValue: string;
  mask?: Mask;
  onValueChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  autoFocus?: boolean;
  style?: TextStyle;
}

export function Converter(props: ConverterProps) {
  const {
    mask,
    onValueChange = () => null,
    onFocus = () => null,
    onBlur = () => null,
    formattedValue,
    style = {},
    autoFocus = true,
  } = props;
  const [localValue, setLocalValue] = useState(formattedValue);
  const bottomSheetInternal = useBottomSheetInternal(true);
  const shouldHandleKeyboardEvents =
    bottomSheetInternal?.shouldHandleKeyboardEvents || {
      value: true,
    };

  useEffect(() => {
    return () => {
      if (Platform.OS === "ios") {
        shouldHandleKeyboardEvents.value = false;
      }
    };
  }, [shouldHandleKeyboardEvents]);

  const handleOnFocus = useCallback(() => {
    if (Platform.OS === "ios") {
      shouldHandleKeyboardEvents.value = true;
    }
    if (onFocus) {
      onFocus();
    }
  }, [onFocus, shouldHandleKeyboardEvents]);
  const handleOnBlur = useCallback(
    (args) => {
      if (Platform.OS === "ios") {
        shouldHandleKeyboardEvents.value = false;
      }
      if (onBlur) {
        onBlur(args);
      }
    },
    [onBlur, shouldHandleKeyboardEvents],
  );

  return (
    <>
      <MaskInput
        testID="mask-input"
        value={localValue}
        onChangeText={(masked) => {
          setLocalValue(masked);
          onValueChange(masked);
        }}
        mask={mask}
        autoFocus={autoFocus}
        style={style}
        onBlur={handleOnBlur}
        keyboardType="numeric"
        returnKeyType="done"
        {...selectTextOnFocus(localValue, handleOnFocus)}
      />
    </>
  );
}
