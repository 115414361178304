import React, { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { WebView } from "react-native-webview";
import { AppStackScreenProps } from "../navigators";

export const HelpScreen: FC<AppStackScreenProps<"help">> = observer(
  function HelpScreen({ route, navigation }) {
    useEffect(() => {
      navigation.setOptions({ title: route.params.title });
    }, [route.params.title]);

    return <WebView source={{ uri: route.params.url }} />;
  },
);
