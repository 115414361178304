import { Pressable } from "react-native";
import React from "react";
import { BottomTabBarButtonProps } from "@react-navigation/bottom-tabs";
import { useBrandTheme } from "../theme/use-brand-theme";

export function TabBarButton(props: BottomTabBarButtonProps) {
  const theme = useBrandTheme();

  return (
    <Pressable
      {...props}
      style={[
        props.style,
        {
          borderTopWidth: 4,
          borderTopColor: props.accessibilityState!.selected
            ? theme.colors.primary
            : theme.colors.border,
        },
      ]}
    />
  );
}
