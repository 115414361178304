import { spacing } from "../theme";
import { Entypo } from "@expo/vector-icons";
import React from "react";
import { View } from "react-native";
import { useBrandTheme } from "../theme/use-brand-theme";
import { useOnboarding } from "./OnboardingContext";

export function Help() {
  const theme = useBrandTheme();
  const { launch } = useOnboarding();

  return (
    <View>
      <Entypo
        name="help-with-circle"
        size={25}
        color={theme.colors.textDim}
        style={{ padding: spacing.micro, marginRight: spacing.small }}
        onPress={launch}
      />
    </View>
  );
}
