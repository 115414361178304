import React from "react";
import { observer } from "mobx-react-lite";
import { palette, spacing } from "../../theme";
import { Sport } from "../../models/sport/sport";
import { translate } from "swunitch-i18n";
import { View } from "react-native";
import { useToast } from "react-native-toast-notifications";
import { CardToggle } from "../CardToggle";

export const ObjectiveModeSegmented = observer(
  function ObjectiveModeSegmented(props: { sport: Sport }) {
    const { sport } = props;
    const toast = useToast();

    const selectMode = (value: boolean) => {
      if (value) {
        sport.updateIsTimeObjective(true);
        toast.show(translate("toast.timeObjective"), {
          type: "success",
          duration: 8000,
          placement: "top",
          successColor: palette.secondary400,
        });
      } else {
        sport.updateIsTimeObjective(false);
        toast.show(translate("toast.distanceObjective"), {
          type: "success",
          duration: 8000,
          placement: "top",
          successColor: palette.secondary400,
        });
      }
    };

    return (
      <View style={{ gap: spacing.small }}>
        <CardToggle
          title={translate("segmented.distanceObjective")}
          description={translate("toast.distanceObjective")}
          value={!sport.isTimeObjective}
          onToggle={() => selectMode(false)}
        />
        <CardToggle
          title={translate("segmented.timeObjective")}
          description={translate("toast.timeObjective")}
          value={sport.isTimeObjective}
          onToggle={() => selectMode(true)}
        />
      </View>
    );
  },
);
