import { TimeConverter } from "./TimeConverter";

class TimeUnit {
  value: number;

  constructor(value: number) {
    this.value = value;
  }

  format(): string {
    return `${this.value}`.padStart(2, "0");
  }
}

export class Time {
  days: TimeUnit;
  hours: TimeUnit;
  minutes: TimeUnit;
  seconds: TimeUnit;

  constructor({
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  }: {
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
  } = {}) {
    this.days = new TimeUnit(days);
    this.hours = new TimeUnit(hours);
    this.minutes = new TimeUnit(minutes);
    this.seconds = new TimeUnit(seconds);
  }

  add(time: Time): Time {
    const seconds =
      TimeConverter.toSeconds(time) + TimeConverter.toSeconds(this);
    return TimeConverter.fromSecondsToDomain(seconds);
  }
}
