import {
  BOTTOM_SHEET_TEXT,
  BOTTOM_SHEET_UNIT_TEXT,
  CENTER_MIDDLE,
  INPUT_SHADOW,
  INPUT_SMALL,
  PRIMARY_FONT,
  UNIT_DISPOSITION,
} from "../../theme/view-style";
import React from "react";
import { Keyboard, TextInput, TouchableOpacity, View } from "react-native";
import { sanitizeKm } from "../../domain/race-calculator/sanitize-km";
import { Text } from "../Text";
import { translate } from "swunitch-i18n";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { selectTextOnFocus } from "../bottom-sheet/selectTextOnFocus";
import { Dashes } from "../Dashes";
import { observer } from "mobx-react-lite";

import { EditableConverter } from "../bottom-sheet/EditableConverter";
import { Distance } from "../../domain/race-calculator/Distance";

interface DistanceDisplayParams {
  distance: Distance;
  layout?: "big" | "small";
  onDistanceChange: (distance: Distance) => void;
  disabled?: boolean;
}

export const DistanceEdition = observer(function DistanceEdition(
  props: DistanceDisplayParams,
) {
  const {
    distance,
    layout = "small",
    onDistanceChange,
    disabled = false,
  } = props;
  const theme = useBrandTheme();

  function updateDistance(d: string) {
    const number = sanitizeKm(d) || 0;

    if (!disabled) {
      onDistanceChange(
        Distance.of(number, distance.distanceSize, distance.isMetricSystem),
      );
    }
  }

  function updateDistanceSize() {
    Keyboard.dismiss();

    if (!disabled) {
      onDistanceChange(distance.switchSize());
    }
  }

  return layout === "small" ? (
    <View style={CENTER_MIDDLE}>
      <TextInput
        style={{
          ...INPUT_SMALL(theme),
        }}
        maxLength={6}
        testID="distance"
        // @ts-ignore
        testProperty={
          distance.value + translate(`units.${distance.humanDistanceUnit()}`)
        }
        defaultValue={`${distance.value}`}
        onChangeText={(d) => updateDistance(d)}
        keyboardType="numeric"
        returnKeyType="done"
        {...selectTextOnFocus(distance.value.toString())}
      />
      <TouchableOpacity onPress={updateDistanceSize}>
        <Text
          style={{
            marginTop: 3,
            lineHeight: 21,
            marginRight: 4,
            ...PRIMARY_FONT,
          }}
        >
          {translate(`units.${distance.humanDistanceUnit()}`)}
        </Text>
        <Dashes borderColor={theme.colors.textDim} />
      </TouchableOpacity>
    </View>
  ) : (
    <View>
      <Text preset="subheading" style={BOTTOM_SHEET_TEXT}>
        {translate("units.distance")}
      </Text>

      <View style={UNIT_DISPOSITION}>
        <EditableConverter
          mask={[/[\d.,]/, /[\d.,]/, /[\d.,]/, /[\d.,]/, /[\d.,]/]}
          onValueChange={(d) => updateDistance(d)}
          value={`${distance.value}`}
          testID="pace"
          disabled={disabled}
        />

        <View style={INPUT_SHADOW}>
          <Text style={BOTTOM_SHEET_UNIT_TEXT} onPress={updateDistanceSize}>
            {translate(`units.${distance.humanDistanceUnit()}`).trim()}
          </Text>
        </View>
      </View>
    </View>
  );
});
