/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import {
  NavigationContainer,
  NavigatorScreenParams,
} from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import * as Linking from "expo-linking";
import { Image, Platform, useColorScheme } from "react-native";
import Config from "../config";
import { navigationRef, useBackButtonHandler } from "./navigationUtilities";
import { useStoreReviewIsAvailable } from "@use-expo/store-review";
import * as StoreReview from "expo-store-review";
import { translate } from "swunitch-i18n";
import { useStores } from "../models";
import { AppDarkTheme, palette } from "../theme";
import { LinkingOptions } from "@react-navigation/native/lib/typescript/src/types";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Race } from "../models/race/race";
import { RaceScreen } from "../screens/race-screen";
import { RacesScreen } from "../screens/races-screen";
import { EmbeddedRaceScreen } from "../screens/embedded-race-screen";
import { AppLightTheme } from "../theme/colors-light";
import { CustomEmbeddedRaceScreen } from "../screens/custom-embedded-race-screen";
import { CustomEmbedRace } from "../domain/race";
import { MapScreen } from "../screens/map-screen";
import { HelpScreen } from "../screens/help-screen";
import { EditRaceScreen } from "../screens/edit-race-screen";
import { ToastProvider } from "react-native-toast-notifications";
import { OnboardingProvider } from "../components/OnboardingContext";
import {
  BottomTabScreenProps,
  createBottomTabNavigator,
} from "@react-navigation/bottom-tabs";
import { Feather } from "@expo/vector-icons";
import { TabBarButton } from "../components/TabBarButton";
import { TrainingScreen } from "../screens/run/training-screen";
import { ProfileScreen } from "../screens/profile-screen";
import { HomeScreen } from "../screens/run/home-screen";
import { Help } from "../components/Help";
import { useBrandTheme } from "../theme/use-brand-theme";
import { imageRegistry } from "../components";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 *   https://reactnavigation.org/docs/typescript/#organizing-types
 */
export type AppStackParamList = {
  app: NavigatorScreenParams<AppTabParamList>;
  help: { url: string; title: string };
  map: { races: Array<Race> };
  race: { race: Race | string };
  "custom-embedded-race": CustomEmbedRace;
  "embedded-race": { race: string };
  "edit-race": { race: Race };
  races: { races?: Array<Race> } | undefined;
};

export type AppTabParamList = {
  home: undefined;
  training: undefined;
  profile: undefined;
};

/**
 * This is a list of all the route names that will exit the app if the back button
 * is pressed while in that screen. Only affects Android.
 */
const exitRoutes = Config.exitRoutes;

export type AppStackScreenProps<T extends keyof AppStackParamList> =
  StackScreenProps<AppStackParamList, T>;

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createNativeStackNavigator<AppStackParamList>();
const AppStack = observer(function AppStack() {
  return (
    <Stack.Navigator
      initialRouteName="app"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="app" component={AppTab} />
      <Stack.Screen
        name="help"
        component={HelpScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: "",
        }}
      />
      <Stack.Screen
        name="map"
        component={MapScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: translate("mapScreen.title"),
        }}
      />
      <Stack.Screen
        name="custom-embedded-race"
        component={CustomEmbeddedRaceScreen}
        options={{
          headerShown: false,
          headerBackTitle: translate("common.back"),
          title: translate("raceScreen.title"),
        }}
      />
      <Stack.Screen
        name="embedded-race"
        component={EmbeddedRaceScreen}
        options={{
          headerShown: false,
          headerBackTitle: translate("common.back"),
          title: translate("raceScreen.title"),
        }}
      />
      <Stack.Screen
        name="race"
        component={RaceScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: translate("raceScreen.title"),
        }}
      />
      <Stack.Screen
        name="edit-race"
        component={EditRaceScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: translate("editRaceScreen.title"),
        }}
      />
      <Stack.Screen
        name="races"
        component={RacesScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: translate("racesScreen.title"),
        }}
      />
    </Stack.Navigator>
  );
});

export type AppTabScreenProps<T extends keyof AppTabParamList> =
  BottomTabScreenProps<AppTabParamList, T>;

const Tab = createBottomTabNavigator<AppTabParamList>();
const AppTab = observer(function AppTab() {
  const theme = useBrandTheme();

  return (
    <Tab.Navigator
      initialRouteName="home"
      screenOptions={() => ({
        tabBarStyle: {
          borderTopWidth: 0,
          height: Platform.select({
            ios: 85,
            android: 55,
          }),
        },
        tabBarActiveTintColor: palette.red,
        tabBarInactiveTintColor: theme.dark
          ? palette.neutral100
          : palette.secondary500,
      })}
    >
      <Tab.Screen
        name="home"
        component={HomeScreen}
        options={{
          tabBarLabel: translate("runScreen.title"),
          tabBarIcon({ color, size }) {
            return <Feather name="home" size={size} color={color} />;
          },
          tabBarButton(props) {
            return <TabBarButton {...props} />;
          },
          headerShown: true,
          title: translate("sports.run"),
          headerTitle: () => (
            <Image
              source={
                theme.dark ? imageRegistry.logoLight : imageRegistry.logoDark
              }
              style={{
                width: 150,
                height: 50,
                resizeMode: "contain",
                alignSelf: "center",
              }}
            />
          ),
          headerRight: () => <Help />,
          headerShadowVisible: false,
          headerTitleAlign: "center",
        }}
      />
      <Tab.Screen
        name="training"
        component={TrainingScreen}
        options={{
          headerShown: false,
          tabBarLabel: translate("trainingScreen.title"),
          tabBarIcon({ color, size }) {
            return <Feather name="activity" size={size} color={color} />;
          },
          tabBarButton(props) {
            return <TabBarButton {...props} />;
          },
        }}
      />
      <Tab.Screen
        name="profile"
        component={ProfileScreen}
        options={{
          headerShown: false,
          tabBarLabel: translate("profileScreen.title"),
          tabBarIcon({ color, size }) {
            return <Feather name="user" size={size} color={color} />;
          },
          tabBarButton(props) {
            return <TabBarButton {...props} />;
          },
        }}
      />
    </Tab.Navigator>
  );
});

interface NavigationProps
  extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

const linking: LinkingOptions<AppStackParamList> = {
  prefixes: [Linking.createURL("/"), "https://pacevisor.com"],
  config: {
    initialRouteName: "app",
    screens: {
      app: {
        initialRouteName: "home",
        path: "/",
      },
      "custom-embedded-race": "/embed/races",
      "embedded-race": "/embed/races/:race",
      race: "/races/:race",
    },
  },
};

export const AppNavigator = observer(function AppNavigator(
  props: NavigationProps,
) {
  const colorScheme = useColorScheme();

  useBackButtonHandler((routeName) => exitRoutes.includes(routeName));

  const { globalStore } = useStores();
  const [isStoreReviewAvailable] = useStoreReviewIsAvailable();

  useEffect(() => {
    globalStore.hasOpenedScreen();
  }, []);

  useEffect(() => {
    if (isStoreReviewAvailable && globalStore.isEnoughOpeningsForReview) {
      StoreReview.requestReview();
      globalStore.clearNumberOfOpenings();
    }
  }, [isStoreReviewAvailable, globalStore.isEnoughOpeningsForReview]);

  useEffect(() => {
    globalStore.runMigrations();
  }, []);

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={
        colorScheme === "dark" && Platform.OS !== "web"
          ? AppDarkTheme
          : AppLightTheme
      }
      // @ts-ignore
      linking={linking}
      {...props}
    >
      <ToastProvider>
        <OnboardingProvider>
          <AppStack />
        </OnboardingProvider>
      </ToastProvider>
    </NavigationContainer>
  );
});
