import React, { useEffect, useState } from "react";
import { Platform, View } from "react-native";
import { observer } from "mobx-react-lite";
import { EnhancedSegment } from "../../services/GeoJsonEnhancer";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { Metadata, MetadataPoint } from "../../services/GeoJsonMetadata";
import { ExpoLeaflet } from "expo-leaflet";
import * as Device from "expo-device";
import { DeviceType } from "expo-device";
import { Race } from "../../models/race/race";
import { ExpoLeafletProps } from "expo-leaflet/web/src/ExpoLeaflet.types";
import { Button } from "../Button";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { palette } from "../../theme";

interface RaceMapProps {
  selectedSegment: EnhancedSegment;
  metadata: Metadata | null;
  profile: EnhancedSegment[];
  race: Race;
  onMoveStart: () => void;
  onMoveEnd: () => void;
}

interface MetadataPointWithTitle extends MetadataPoint {
  title: string;
}

export const EXPO_LEAFLET_PROPS: Pick<
  ExpoLeafletProps,
  "mapLayers" | "mapOptions"
> = {
  mapLayers: [
    {
      attribution:
        '<a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      baseLayerIsChecked: true,
      baseLayerName: "OpenStreetMap",
      layerType: "TileLayer",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      baseLayer: true,
    },
  ],
  mapOptions: {
    scrollWheelZoom: false,
    zoomControl: Platform.OS === "web",
    dragging: !(
      Platform.OS === "web" && Device.deviceType === DeviceType.PHONE
    ),
  },
};

export const RaceMap = observer(function RaceMap(props: RaceMapProps) {
  const { selectedSegment, metadata, onMoveEnd, onMoveStart, race, profile } =
    props;
  const theme = useBrandTheme();
  const { colors } = theme;
  const [marker, setMarker] = useState<Omit<MetadataPoint, "distance">>({
    lat: 0,
    lon: 0,
  });
  const [waypoints, setWaypoints] = useState<MetadataPointWithTitle[]>([]);
  const [shape, setShape] = useState<[number, number][]>([]);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const foundSegment = profile.find((i) => {
      return i.distance >= selectedSegment.distance;
    });
    const lastSegment: EnhancedSegment | undefined =
      profile[profile.length - 1];

    const found: Omit<MetadataPoint, "distance"> = foundSegment
      ? {
          lat: foundSegment.coordinates[1],
          lon: foundSegment.coordinates[0],
        }
      : lastSegment
        ? {
            lat: lastSegment.coordinates[1],
            lon: lastSegment.coordinates[0],
          }
        : {
            lat: 0,
            lon: 0,
          };

    setMarker(found);
  }, [selectedSegment, profile]);

  useEffect(() => {
    const bounds: [number, number][] =
      metadata?.points.map((i) => {
        return [i.lat, i.lon];
      }) || [];
    setShape(bounds);
  }, [metadata]);

  useEffect(() => {
    const points = race.waypoints
      .map((w) => {
        const found = metadata?.points.find((p) => {
          return p.distance >= w.distance;
        });
        if (!found) {
          return null;
        }

        return {
          ...found,
          title: w.title,
        };
      })
      .filter((p): p is MetadataPointWithTitle => p !== null);

    setWaypoints(points);
  }, [race, metadata]);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const expoLeaflet = metadata ? (
    <ExpoLeaflet
      {...EXPO_LEAFLET_PROPS}
      mapCenterPosition={{
        lat: metadata.center.lat,
        lng: metadata.center.lon,
      }}
      zoom={Platform.OS === "web" ? undefined : 13}
      onMessage={(e) => {
        if (e.tag === "onMoveStart") {
          onMoveStart();
        } else if (e.tag === "onMoveEnd") {
          onMoveEnd();
        }
      }}
      mapMarkers={[
        {
          position: {
            lat: marker.lat,
            lng: marker.lon,
          },
          id: "1",
          icon: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" style="margin-top:-2.5px;">
<circle cx="10" cy="10" r="5" fill="${colors.primary}" stroke="black" stroke-width="2"/>
</svg>`,
          size: [5, 5],
        },
        ...waypoints.map((waypoint, index) => ({
          position: {
            lat: waypoint.lat,
            lng: waypoint.lon,
          },
          id: `waypoint-${index}`,
          icon: `
<div style="background-color: ${colors.primary}AA;
color: white;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
padding: 2px;
border-radius: 5px;
font-size: 9px;
font-weight: bold;">
${waypoint.title}
</div>
              `,
        })),
      ]}
      mapShapes={[
        {
          shapeType: "polyline",
          color: colors.primary,
          id: "5",
          positions: shape,
        },
      ]}
    />
  ) : null;
  return (
    <View
      style={{
        height: isFullscreen ? 400 : 180,
      }}
    >
      {isFullscreen ? expoLeaflet : null}
      {!isFullscreen ? expoLeaflet : null}

      <Button
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 1000,
        }}
        preset="rounded"
        onPress={toggleFullscreen}
        RightAccessory={() => (
          <MaterialCommunityIcons
            name={isFullscreen ? "fullscreen-exit" : "fullscreen"}
            size={25}
            color={palette.neutral900}
          />
        )}
      />
    </View>
  );
});
