import { format, Locale, parseISO } from "date-fns";

import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import { getCurrentLocale } from "swunitch-i18n";

type Options = Parameters<typeof format>[2];

const getLocale = (): Locale => {
  const locale = getCurrentLocale().split("-")[0];
  return locale === "fr" ? fr : en;
};

export const formatDate = (
  date: string,
  locale?: string,
  dateFormat?: string,
  options?: Options,
) => {
  const defaultLocale = getLocale();
  const dateOptions = {
    ...options,
    locale: (locale && locale === "fr" ? fr : en) || defaultLocale,
  };
  return format(parseISO(date), dateFormat ?? "dd MMMM yyyy", dateOptions);
};

export const getMonth = (date?: string) => {
  if (!date) return "";
  const locale = getLocale();
  return format(parseISO(date), "MMMM", { locale });
};

export const getDay = (date?: string) => {
  if (!date) return "";
  const locale = getLocale();
  return format(parseISO(date), "d", { locale });
};

export const getHour = (date?: string) => {
  if (!date) return "";
  const isoDate = parseISO(date);
  const hours = isoDate.getUTCHours();
  const minutes = isoDate.getUTCMinutes();
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

export const getYear = (date?: string) => {
  if (!date) return "";
  const locale = getLocale();
  return format(parseISO(date), "yy", { locale });
};

export const getFullYear = (date?: string) => {
  if (!date) return "";
  const locale = getLocale();
  return format(parseISO(date), "yyyy", { locale });
};
