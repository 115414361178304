import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  AppStackParamList,
  AppStackScreenProps,
  AppTabScreenProps,
} from "../navigators";
import { CompositeScreenProps, useNavigation } from "@react-navigation/native";
import { useBrandTheme } from "../theme/use-brand-theme";
import { BACKGROUND_CONTAINER, CONTAINER } from "../theme/view-style";
import { spacing } from "../theme";
import { ImageBackground, StyleSheet, View } from "react-native";
import {
  imageRegistry,
  Screen,
  Text,
  useSafeAreaInsetPadding,
} from "../components";
import { AddCourse } from "./run/AddCourse";
import { ListRaces } from "./run/ListRaces";
import { translate } from "swunitch-i18n";
import { StackNavigationProp } from "@react-navigation/stack";
import { useStores } from "../models";
import { RaceType } from "../models/race/race-type";
import { SportsTab } from "../components/segmented/SportsTab";
import { useOrientation } from "../utils/orientation-style";

export const ProfileScreen: FC<
  CompositeScreenProps<AppTabScreenProps<"profile">, AppStackScreenProps<"app">>
> = observer(function ProfileScreen() {
  const { globalStore } = useStores();
  const theme = useBrandTheme();
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const [type, setType] = useState<RaceType | "all">(RaceType.Run);
  const normalizedType = type === "all" ? RaceType.Run : type;
  const deviceOrientation = useOrientation();
  const insetPadding = useSafeAreaInsetPadding(["top"]);

  function createLocalRace() {
    const race = globalStore.createLocalRace(normalizedType);
    navigation.navigate("edit-race", { race });
  }

  return (
    <Screen preset="scroll">
      <View
        style={{
          ...BACKGROUND_CONTAINER(theme),
          gap: spacing.large,
        }}
      >
        <View
          style={[
            CONTAINER(deviceOrientation),
            { paddingVertical: spacing.small },
            insetPadding,
          ]}
        >
          <ImageBackground
            source={imageRegistry.blueBackground}
            style={{
              ...StyleSheet.absoluteFillObject,
            }}
          />

          <SportsTab
            onTypeChange={(t) => {
              setType(t);
            }}
            type={type}
            hideAll={true}
          />
        </View>

        <View style={CONTAINER(deviceOrientation)}>
          <Text preset="heading" tx="profileScreen.title" />
          <Text>Votre profil va s'enrichir de nouvelles fonctionnalités.</Text>
          <Text>
            Vous allez bientôt pouvoir ajouter tous vos paramètres : VMA, FTP,
            etc.
          </Text>
        </View>

        <AddCourse onPress={createLocalRace} type={normalizedType} />

        <ListRaces
          title={translate("profileScreen.localCourses")}
          races={globalStore.localRaces.filter(
            (r) => r.type === normalizedType,
          )}
          computeTime={true}
          carousel={true}
          displayMap={false}
        />
      </View>
    </Screen>
  );
});
