import { Segment } from "./GeoJsonSegmenter";
import { AdjusterConstructor } from "./GeoJsonEnhancer";

export class RunAdjuster {
  private data: AdjusterConstructor;

  constructor(data: AdjusterConstructor) {
    this.data = data;
  }

  getSpeedAdjustment(segment: Segment): number {
    let speed = this.data.baseSpeed;
    speed *= this.getSpeedAdjustmentForUphill(segment);
    speed *= this.getSpeedAdjustmentForDownhill(segment);
    return speed;
  }

  private getSpeedAdjustmentForUphill(segment: Segment): number {
    if (segment.elevationGain === 0) {
      return 1;
    }

    const incline = (segment.elevationGain / segment.length) * 100;
    // V. Billat
    const coefficients = [
      1, 1.05, 1.1, 1.15, 1.2, 1.3, 1.35, 1.4, 1.45, 1.5, 1.55, 1.6, 1.7, 1.8,
      1.9, 2, 2.25, 2.5, 2.75, 3,
    ];
    const index = Math.min(Math.floor(incline), coefficients.length - 1);
    return (1 / coefficients[index]) * this.data.climberAbility;
  }

  private getSpeedAdjustmentForDownhill(segment: Segment): number {
    if (segment.elevationLoss === 0) {
      return 1;
    }

    const DAVIES_LIMIT_12_PERCENT = 12;
    const incline = (segment.elevationLoss / segment.length) * 100;
    const adjustedPente = Math.min(incline, DAVIES_LIMIT_12_PERCENT);

    // Réduction du coût énergétique de 1,5 ml/kg/min, soit 0,35 Km/h en plus
    const speedIncreaseKmH = (0.35 * adjustedPente) / 1.5;

    const speedIncreaseMpH = speedIncreaseKmH * 1000;
    const speedIncreaseFactor = 1 + speedIncreaseMpH / this.data.baseSpeed;

    return speedIncreaseFactor * this.data.descenderAbility;
  }
}
