import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppStackScreenProps } from "../navigators";
import { api } from "../services/api";
import { Race, RaceModel } from "../models/race/race";
import { RaceAnalysis } from "../components/race-analysis/RaceAnalysis";
import { defaultRace } from "./default-race";
import { recordEvent } from "../services/plausible";

export const RaceScreen: FC<AppStackScreenProps<"race">> = observer(
  function RaceScreen({ route }) {
    const { race: raceOrCodeFromProps } = route.params;
    const [race, setRace] = useState<Race>(defaultRace);

    useEffect(() => {
      if (!raceOrCodeFromProps) return;

      if (typeof raceOrCodeFromProps === "string") {
        api.getRace(raceOrCodeFromProps).then((result) => {
          if (result.kind === "ok") {
            setRace(RaceModel.create(result.race));
          }
        });
      } else {
        setRace(raceOrCodeFromProps);
      }
    }, [raceOrCodeFromProps]);

    useEffect(() => {
      if (race.id && !race.isLocal) {
        recordEvent(`races/${race.id}`);
      }
    }, [race]);

    return <RaceAnalysis race={race} isInIframe={false} />;
  },
);
