import { observer } from "mobx-react-lite";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import SegmentedControl from "react-native-segmented-control-2";
import React from "react";
import { TriathlonIcon } from "../sports/triathlon/TriathlonIcon";
import { AppDarkTheme, palette, spacing } from "../../theme";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { RaceType } from "../../models/race/race-type";

export const SportsTab = observer(function SportsTab(props: {
  onTypeChange: (type: RaceType | "all") => void;
  type: RaceType | "all";
  hideAll?: boolean;
}) {
  const theme = useBrandTheme();

  return (
    <SegmentedControl
      onChange={(index) => {
        if (index === 3) {
          props.onTypeChange("all");
        } else {
          props.onTypeChange(getTypeFromIndex(index));
        }
      }}
      value={getIndexFromType(props.type)}
      style={{
        borderRadius: 100,
        width: "100%",
      }}
      tabStyle={{
        borderRadius: 100,
        paddingVertical: spacing.extraSmall - 2,
      }}
      selectedTabStyle={{
        borderRadius: 100,
      }}
      tabs={[
        <MaterialCommunityIcons
          key={1}
          name="run-fast"
          size={18}
          color={
            getIndexFromType(props.type) === 0
              ? AppDarkTheme.colors.text
              : palette.neutral900
          }
        />,
        <MaterialCommunityIcons
          key={2}
          name="bike-fast"
          size={18}
          color={
            getIndexFromType(props.type) === 1
              ? AppDarkTheme.colors.text
              : palette.neutral900
          }
        />,
        <MaterialCommunityIcons
          key={3}
          name="swim"
          size={18}
          color={
            getIndexFromType(props.type) === 2
              ? AppDarkTheme.colors.text
              : palette.neutral900
          }
        />,
        ...(props.hideAll
          ? []
          : [
              <TriathlonIcon
                key={4}
                color={
                  getIndexFromType(props.type) === 3
                    ? AppDarkTheme.colors.text
                    : palette.neutral900
                }
              />,
            ]),
      ]}
      activeTabColor={theme.colors.primary}
      activeTextColor={AppDarkTheme.colors.text}
    />
  );
});

const SegmentedControlIndex = {
  Run: 0,
  Bike: 1,
  Swim: 2,
  Triathlon: 3,
};

function getTypeFromIndex(index: number): RaceType {
  if (index === SegmentedControlIndex.Run) {
    return RaceType.Run;
  }
  if (index === SegmentedControlIndex.Bike) {
    return RaceType.Bike;
  }
  if (index === SegmentedControlIndex.Swim) {
    return RaceType.Swim;
  }
  return RaceType.Run;
}

function getIndexFromType(type: RaceType | "all"): number {
  if (type === RaceType.Run) {
    return SegmentedControlIndex.Run;
  }
  if (type === RaceType.Bike) {
    return SegmentedControlIndex.Bike;
  }
  if (type === RaceType.Swim) {
    return SegmentedControlIndex.Swim;
  }
  if (type === "all") {
    return SegmentedControlIndex.Triathlon;
  }
  return SegmentedControlIndex.Run;
}
