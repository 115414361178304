import i18n from "i18n-js";
import React from "react";
import {
  StyleProp,
  Text as RNText,
  TextProps as RNTextProps,
  TextStyle,
} from "react-native";
import { translate, TxKeyPath } from "swunitch-i18n";
import { typography } from "../theme";
import { BrandTheme, useBrandTheme } from "../theme/use-brand-theme";
import { PRIMARY_SEMI_BOLD_FONT } from "../theme/view-style";

type Sizes = keyof typeof $sizeStyles;
type Weights = keyof typeof typography.primary;
type Presets = keyof ReturnType<typeof $presets>;

export interface TextProps extends RNTextProps {
  /**
   * Text which is looked up via i18n.
   */
  tx?: TxKeyPath;
  /**
   * The text to display if not using `tx` or nested components.
   */
  text?: string;
  /**
   * Optional options to pass to i18n. Useful for interpolation
   * as well as explicitly setting locale or translation fallbacks.
   */
  txOptions?: i18n.TranslateOptions;
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<TextStyle>;
  /**
   * One of the different types of text presets.
   */
  preset?: Presets;
  /**
   * Text weight modifier.
   */
  weight?: Weights;
  /**
   * Text size modifier.
   */
  size?: Sizes;
  /**
   * Children components.
   */
  children?: React.ReactNode;
}

/**
 * For your text displaying needs.
 * This component is a HOC over the built-in React Native one.
 *
 * - [Documentation and Examples](https://github.com/infinitered/ignite/blob/master/docs/Components-Text.md)
 */
export function Text(props: TextProps) {
  const {
    weight,
    size,
    tx,
    txOptions,
    text,
    children,
    style: $styleOverride,
    ...rest
  } = props;
  const theme = useBrandTheme();

  const i18nText = tx && translate(tx, txOptions);
  const content = i18nText || text || children;

  const preset: Presets = $presets(theme)[props.preset!]
    ? props.preset!
    : "default";
  const $styles = [
    $presets(theme)[preset],
    $fontWeightStyles[weight!],
    $sizeStyles[size!],
    $styleOverride,
  ];

  return (
    <RNText {...rest} style={$styles}>
      {content}
    </RNText>
  );
}

const $sizeStyles = {
  xxl: { fontSize: 36, lineHeight: 44 } as TextStyle,
  xl: { fontSize: 28, lineHeight: 34 } as TextStyle,
  lg: { fontSize: 20, lineHeight: 32 } as TextStyle,
  md: { fontSize: 18, lineHeight: 26 } as TextStyle,
  sm: { fontSize: 16, lineHeight: 24 } as TextStyle,
  xs: { fontSize: 14, lineHeight: 21 } as TextStyle,
  xxs: { fontSize: 12, lineHeight: 18 } as TextStyle,
};

const $fontWeightStyles = Object.entries(typography.primary).reduce(
  (acc, [weight, fontFamily]) => {
    return { ...acc, [weight]: { fontFamily } };
  },
  {},
) as Record<Weights, TextStyle>;

const $baseStyle = (theme: BrandTheme): StyleProp<TextStyle> => [
  $sizeStyles.sm,
  $fontWeightStyles.normal,
  { color: theme.colors.text },
];

const $presets = (theme: BrandTheme) => ({
  default: $baseStyle(theme),

  bold: [$baseStyle(theme), $fontWeightStyles.bold] as StyleProp<TextStyle>,

  heading: [
    $baseStyle(theme),
    $sizeStyles.xl,
    $fontWeightStyles.bold,
    PRIMARY_SEMI_BOLD_FONT,
  ] as StyleProp<TextStyle>,

  subheading: [
    $baseStyle(theme),
    $sizeStyles.lg,
    $fontWeightStyles.medium,
    PRIMARY_SEMI_BOLD_FONT,
  ] as StyleProp<TextStyle>,

  formLabel: [
    $baseStyle(theme),
    $fontWeightStyles.medium,
  ] as StyleProp<TextStyle>,

  formHelper: [
    $baseStyle(theme),
    $sizeStyles.sm,
    $fontWeightStyles.normal,
  ] as StyleProp<TextStyle>,
});
