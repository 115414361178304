import React, { useMemo } from "react";
import { View } from "react-native";
import { observer } from "mobx-react-lite";
import { Race } from "../../models/race/race";
import { Text } from "../Text";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { CONTAINER } from "../../theme/view-style";
import { useOrientation } from "../../utils/orientation-style";
import { Statistics } from "./Statistics";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { formatNumber } from "../../utils/formatNumber";
import { degreeToOrientation } from "../../services/degreeToOrientation";
import { translate } from "swunitch-i18n";

export const RaceForecast = observer(function RaceForecast(props: {
  race: Race;
}) {
  const { race } = props;
  const theme = useBrandTheme();
  const deviceOrientation = useOrientation();

  const averageForecast = useMemo(() => {
    const totalForecasts = race.forecasts.length;
    const totalTemperature = race.forecasts.reduce(
      (sum, forecast) => sum + (forecast.temperature || 0),
      0,
    );
    const totalPrecipitation = race.forecasts.reduce(
      (sum, forecast) => sum + (forecast.precipitation || 0),
      0,
    );
    const totalWindSpeed = race.forecasts.reduce(
      (sum, forecast) => sum + (forecast.windSpeed?.ele10m || 0),
      0,
    );
    const totalWindDirection = race.forecasts.reduce(
      (sum, forecast) => sum + (forecast.windDirection?.ele10m || 0),
      0,
    );

    return {
      averageTemperature: totalTemperature / totalForecasts,
      averagePrecipitation: totalPrecipitation / totalForecasts,
      averageWindSpeed: (totalWindSpeed / totalForecasts) * 3.6,
      averageWindDirection: totalWindDirection / totalForecasts,
    };
  }, [race.forecasts]);

  return (
    <View>
      <View style={CONTAINER(deviceOrientation)}>
        <Text preset="subheading" tx="raceScreen.forecast" />

        <Statistics
          width="49.4%"
          statistics={[
            {
              value: `${formatNumber(averageForecast.averageTemperature)}°C`,
              iconText: translate("units.temperature"),
              icon: (
                <MaterialCommunityIcons
                  name="thermometer"
                  size={15}
                  color={theme.colors.textDim}
                />
              ),
            },
            {
              value: `${formatNumber(averageForecast.averagePrecipitation)}mm`,
              iconText: translate("units.precipitation"),
              icon: (
                <MaterialCommunityIcons
                  name="weather-rainy"
                  size={15}
                  color={theme.colors.textDim}
                />
              ),
            },
            {
              value: `${formatNumber(averageForecast.averageWindSpeed)} km/h`,
              iconText: translate("units.windSpeed"),
              icon: (
                <MaterialCommunityIcons
                  name="weather-windy"
                  size={15}
                  color={theme.colors.textDim}
                />
              ),
            },
            {
              value: `${degreeToOrientation(averageForecast.averageWindDirection)}`,
              iconText: translate("units.windDirection"),
              icon: (
                <FontAwesome
                  name="arrow-up"
                  size={15}
                  color={theme.colors.textDim}
                  style={{
                    transform: [
                      {
                        rotate: `${averageForecast.averageWindDirection - 180}deg`,
                      },
                    ],
                  }}
                />
              ),
            },
          ]}
        />
      </View>
    </View>
  );
});
