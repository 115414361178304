import * as turf from "@turf/turf";
import { Feature, LineString } from "@turf/turf";
import { FeatureCollection, Geometry } from "@turf/helpers";

export class GeoJsonSegmenter {
  private route: Feature<LineString>;

  constructor(geoJson: FeatureCollection<Geometry>) {
    this.route = geoJson.features[0] as Feature<LineString>;
  }

  public segmentTrail(segmentLength: number): Segment[] {
    const segments: Segment[] = [];
    let totalDistance = 0;
    let elevationGain = 0;
    let elevationLoss = 0;
    let lastElevation = this.route.geometry.coordinates[0][2] ?? 0;
    let segmentCoordinates: number[] = this.route.geometry.coordinates[0];

    for (let i = 1; i < this.route.geometry.coordinates.length; i++) {
      const currentPoint = this.route.geometry.coordinates[i];
      const previousPoint = this.route.geometry.coordinates[i - 1];
      const distance = turf.distance(previousPoint, currentPoint, {
        units: "meters",
      });
      totalDistance += distance;

      const currentElevation = currentPoint[2] ?? lastElevation;
      if (currentElevation > lastElevation) {
        elevationGain += currentElevation - lastElevation;
      } else {
        elevationLoss += lastElevation - currentElevation;
      }
      lastElevation = currentElevation;

      while (totalDistance >= segmentLength) {
        const segmentElevationChange = elevationGain - elevationLoss;
        const percentAngle = (segmentElevationChange / segmentLength) * 100;

        const segmentName = segments.length * segmentLength + segmentLength;
        segments.push({
          distance: segmentName,
          length: segmentLength,
          elevationGain,
          elevationLoss,
          altitude: lastElevation,
          percentAngle,
          coordinates: [...segmentCoordinates],
        });
        totalDistance -= segmentLength;
        elevationGain = 0;
        elevationLoss = 0;
        segmentCoordinates = currentPoint;
      }
    }

    if (totalDistance > 0) {
      const segmentElevationChange = elevationGain - elevationLoss;
      const percentAngle = (segmentElevationChange / totalDistance) * 100;

      const segmentName = segments.length * segmentLength + totalDistance;
      segments.push({
        distance: segmentName,
        length: totalDistance,
        elevationGain,
        elevationLoss,
        altitude: lastElevation,
        percentAngle,
        coordinates: [...segmentCoordinates],
      });
    }

    return segments;
  }
}

export interface Segment {
  distance: number;
  length: number;
  elevationGain: number;
  elevationLoss: number;
  altitude: number;
  percentAngle: AngleType | number;
  coordinates: number[];
}

export enum AngleType {
  LOW = 3,
  MEDIUM = 6,
  HIGH = 9,
}
