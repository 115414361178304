import { KilometerPerHour } from "../speed-converters/KilometerPerHour";
import { DistanceSize } from "./DistanceSize";
import { NB_OF_YARD_IN_A_MILE } from "./constants";

export class Distance {
  value: number;
  distanceSize: DistanceSize;
  isMetricSystem: boolean;

  private constructor(
    value: number,
    size: DistanceSize,
    isMetricSystem: boolean,
  ) {
    this.value = value;
    this.distanceSize = size;
    this.isMetricSystem = isMetricSystem;
  }

  static of(value: number, size: DistanceSize, isMetricSystem = true) {
    return new Distance(value, size, isMetricSystem);
  }

  static detect(valueInKm: number, isMetricSystem = true) {
    if (Math.floor(valueInKm) === 0) {
      return new Distance(
        KilometerPerHour.toMeterPerHour(valueInKm),
        DistanceSize.short,
        isMetricSystem,
      );
    } else {
      return new Distance(valueInKm, DistanceSize.long, isMetricSystem);
    }
  }

  getInSmallSize(): number {
    if (this.distanceSize === DistanceSize.short) {
      if (this.isMetricSystem) {
        return this.value;
      } else {
        return (this.value / NB_OF_YARD_IN_A_MILE) * 1000;
      }
    } else {
      return this.value * 1000;
    }
  }

  switchSize(): Distance {
    const distanceSize =
      this.distanceSize === DistanceSize.short
        ? DistanceSize.long
        : DistanceSize.short;

    return Distance.of(this.value, distanceSize, this.isMetricSystem);
  }

  humanDistanceUnit(): "kilometer" | "meter" | "mile" | "yard" {
    if (this.isMetricSystem) {
      if (this.distanceSize === DistanceSize.long) {
        return "kilometer";
      } else {
        return "meter";
      }
    } else {
      if (this.distanceSize === DistanceSize.long) {
        return "mile";
      } else {
        return "yard";
      }
    }
  }
}
