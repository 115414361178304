import tj from "@mapbox/togeojson";
import { DOMParser } from "xmldom";
import { FeatureCollection, Geometry } from "@turf/helpers";

export class GPXParser {
  public static convertGPXToGeoJSON(
    gpxContent: string,
  ): FeatureCollection<Geometry> {
    const dom = new DOMParser().parseFromString(gpxContent);
    return tj.gpx(dom);
  }
}
