import { translate } from "swunitch-i18n";

export const ABILITIES = [0.5, 0.75, 1, 1.25, 1.5];

export function numberToAbility(ability: number): string {
  "worklet";

  if (ability <= 0.5) {
    return translate("raceScreen.ability1");
  }
  if (ability <= 0.75) {
    return translate("raceScreen.ability2");
  }
  if (ability <= 1) {
    return translate("raceScreen.ability3");
  }
  if (ability <= 1.25) {
    return translate("raceScreen.ability4");
  }
  if (ability <= 1.5) {
    return translate("raceScreen.ability5");
  }
  return "";
}
