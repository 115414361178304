import React, { FC, useMemo } from "react";
import { useWindowDimensions, View } from "react-native";
import { observer } from "mobx-react-lite";
import { Screen } from "../components";
import { AppStackParamList, AppStackScreenProps } from "../navigators";
import { useBrandTheme } from "../theme/use-brand-theme";
import { ExpoLeaflet } from "expo-leaflet";
import { EXPO_LEAFLET_PROPS } from "../components/race-analysis/RaceMap";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

export const MapScreen: FC<AppStackScreenProps<"map">> = observer(
  function MapScreen({ route }) {
    const theme = useBrandTheme();
    const layout = useWindowDimensions();
    const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();

    const races = useMemo(
      () =>
        route.params.races
          .filter((race) => race.latitude && race.longitude)
          .map((race) => ({
            position: {
              lat: race.latitude!,
              lng: race.longitude!,
            },
            id: race.id,
            icon: `
<div style="background-color: ${theme.colors.primary}AA;
color: white;
padding: 2px;
border-radius: 5px;
font-size: 9px;
font-weight: bold;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
max-width: 70px;">
${race.title}
</div>
    `,
          })),
      [route.params.races],
    );

    return (
      <Screen>
        <View style={{ height: layout.height - 60 }}>
          <ExpoLeaflet
            {...EXPO_LEAFLET_PROPS}
            mapCenterPosition={{
              lat: 46.71109,
              lng: 1.7191036,
            }}
            zoom={5}
            onMessage={(e) => {
              if (e.tag === "onMapMarkerClicked") {
                navigation.navigate("race", { race: e.mapMarkerId });
              }
            }}
            mapMarkers={races}
          />
        </View>
      </Screen>
    );
  },
);
