import * as ExpoLocation from "expo-location";
import * as Linking from "expo-linking";
import { alertMessage } from "./AlertMessage";

export class LocationService {
  static async refreshNearMeLocation(
    callback: (latitude: number, longitude: number) => void,
  ) {
    const alreadyForegroundPermission =
      await ExpoLocation.getForegroundPermissionsAsync();
    if (!alreadyForegroundPermission.granted) {
      await alertMessage(
        "runScreen.needLocationTitle",
        "runScreen.needLocationDescription",
      );
    }

    const foregroundPermission =
      await ExpoLocation.requestForegroundPermissionsAsync();
    if (!foregroundPermission.granted) {
      return Linking.openSettings();
    }

    const location = await ExpoLocation.getCurrentPositionAsync({});
    callback(location.coords.latitude, location.coords.longitude);
  }

  static isLocationEnabled() {
    return ExpoLocation.getForegroundPermissionsAsync();
  }
}
