import React, { ErrorInfo } from "react";
import { ScrollView, ViewStyle } from "react-native";
import { Button, Screen, Text } from "../../components";
import { spacing } from "../../theme";
import { useBrandTheme } from "../../theme/use-brand-theme";

export interface ErrorDetailsProps {
  error: Error;
  errorInfo: ErrorInfo;

  onReset(): void;
}

export function ErrorDetails(props: ErrorDetailsProps) {
  const { colors } = useBrandTheme();

  return (
    <Screen
      preset="fixed"
      safeAreaEdges={["top", "bottom"]}
      contentContainerStyle={$contentContainer}
    >
      <ScrollView
        style={{
          flex: 2,
          backgroundColor: colors.separator,
          marginVertical: spacing.medium,
          borderRadius: 6,
        }}
        contentContainerStyle={$errorSectionContentContainer}
      >
        <Text
          style={{
            color: colors.error,
          }}
          weight="medium"
          text={`${props.error}`.trim()}
        />
        <Text
          selectable
          style={{
            marginTop: spacing.medium,
            color: colors.textDim,
          }}
          text={`${props.errorInfo.componentStack}`.trim()}
        />
      </ScrollView>

      <Button
        preset="reversed"
        style={{
          backgroundColor: colors.error,
          paddingHorizontal: spacing.huge,
        }}
        onPress={props.onReset}
        tx="errorScreen.reset"
      />
    </Screen>
  );
}

const $contentContainer: ViewStyle = {
  alignItems: "center",
  paddingHorizontal: spacing.large,
  paddingTop: spacing.extraLarge,
  flex: 1,
};

const $errorSectionContentContainer: ViewStyle = {
  padding: spacing.medium,
};
