import { Theme } from "@react-navigation/native/src/types";
import { useTheme } from "@react-navigation/native";
import { AppDarkTheme } from "../theme";

export type BrandTheme = Theme & {
  colors: Pick<Theme, "colors"> & typeof AppDarkTheme.colors;
};

export function useBrandTheme(): BrandTheme {
  // @ts-ignore
  return useTheme();
}
