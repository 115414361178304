import React, { ComponentType } from "react";
import {
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  StyleProp,
  TextStyle,
  ViewStyle,
} from "react-native";
import { palette, spacing, typography } from "../theme";
import { Text, TextProps } from "./Text";
import { BrandTheme, useBrandTheme } from "../theme/use-brand-theme";

type Presets = keyof ReturnType<typeof $viewPresets>;

export interface ButtonAccessoryProps {
  style: StyleProp<any>;
  pressableState: PressableStateCallbackType;
}

export interface ButtonProps extends PressableProps {
  /**
   * Text which is looked up via i18n.
   */
  tx?: TextProps["tx"];
  /**
   * The text to display if not using `tx` or nested components.
   */
  text?: TextProps["text"];
  /**
   * Optional options to pass to i18n. Useful for interpolation
   * as well as explicitly setting locale or translation fallbacks.
   */
  txOptions?: TextProps["txOptions"];
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>;
  /**
   * An optional style override for the "pressed" state.
   */
  pressedStyle?: StyleProp<ViewStyle>;
  /**
   * An optional style override for the button text.
   */
  textStyle?: StyleProp<TextStyle>;
  /**
   * An optional style override for the button text when in the "pressed" state.
   */
  pressedTextStyle?: StyleProp<TextStyle>;
  /**
   * One of the different types of button presets.
   */
  preset?: Presets;
  /**
   * An optional component to render on the right side of the text.
   * Example: `RightAccessory={(props) => <View {...props} />}`
   */
  RightAccessory?: ComponentType<ButtonAccessoryProps>;
  /**
   * An optional component to render on the left side of the text.
   * Example: `LeftAccessory={(props) => <View {...props} />}`
   */
  LeftAccessory?: ComponentType<ButtonAccessoryProps>;
  /**
   * Children components.
   */
  children?: React.ReactNode;
  /**
   * Children components.
   */
  MiddleAccessory?: React.ReactNode;

  loading?: boolean;
}

/**
 * A component that allows users to take actions and make choices.
 * Wraps the Text component with a Pressable component.
 *
 * - [Documentation and Examples](https://github.com/infinitered/ignite/blob/master/docs/Components-Button.md)
 */
export function Button(props: ButtonProps) {
  const {
    tx,
    text,
    txOptions,
    style: $viewStyleOverride,
    pressedStyle: $pressedViewStyleOverride,
    textStyle: $textStyleOverride,
    pressedTextStyle: $pressedTextStyleOverride,
    children,
    RightAccessory,
    LeftAccessory,
    MiddleAccessory,
    loading,
    ...rest
  } = props;
  const theme = useBrandTheme();

  const preset: Presets = $viewPresets(theme)[props.preset!]
    ? props.preset!
    : "default";

  function $viewStyle({ pressed }) {
    return [
      $viewPresets(theme)[preset],
      $viewStyleOverride,
      !!pressed && [
        $pressedViewPresets(theme)[preset],
        $pressedViewStyleOverride,
      ],
    ];
  }

  function $textStyle({ pressed }) {
    return [
      $textPresets[preset],
      $textStyleOverride,
      !!pressed && [$pressedTextPresets[preset], $pressedTextStyleOverride],
    ];
  }

  return (
    <Pressable style={$viewStyle} accessibilityRole="button" {...rest}>
      {(state) => (
        <>
          {!!LeftAccessory && (
            <LeftAccessory style={$leftAccessoryStyle} pressableState={state} />
          )}

          {loading ? (
            <Text style={$textStyle(state)} tx="common.loading" />
          ) : (
            MiddleAccessory || (
              <Text
                tx={tx}
                text={text}
                txOptions={txOptions}
                style={$textStyle(state)}
              >
                {children}
              </Text>
            )
          )}

          {!!RightAccessory && (
            <RightAccessory
              style={$rightAccessoryStyle}
              pressableState={state}
            />
          )}
        </>
      )}
    </Pressable>
  );
}

const $baseViewStyle: ViewStyle = {
  borderRadius: 25,
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  paddingVertical: spacing.small,
  paddingHorizontal: spacing.small,
  overflow: "hidden",
};

const $baseTextStyle: TextStyle = {
  fontSize: 16,
  lineHeight: 20,
  fontFamily: typography.primary.medium,
  textAlign: "center",
  flexShrink: 1,
  flexGrow: 0,
  zIndex: 2,
};

const $rightAccessoryStyle: ViewStyle = {
  marginStart: spacing.extraSmall,
  zIndex: 1,
};
const $leftAccessoryStyle: ViewStyle = {
  marginEnd: spacing.extraSmall,
  zIndex: 1,
};

const $viewPresets = (theme: BrandTheme) => ({
  default: [
    $baseViewStyle,
    {
      borderColor: palette.neutral400,
      backgroundColor: palette.neutral100,
    },
  ] as StyleProp<ViewStyle>,

  rounded: [
    $baseViewStyle,
    {
      borderRadius: 50,
      backgroundColor: palette.neutral100,
      paddingVertical: spacing.extraSmall,
      paddingHorizontal: spacing.extraSmall,
    },
  ] as StyleProp<ViewStyle>,

  filled: [
    $baseViewStyle,
    { backgroundColor: palette.neutral300 },
  ] as StyleProp<ViewStyle>,

  outlined: [
    $baseViewStyle,
    {
      borderColor: palette.secondary500,
      borderWidth: 1,
      backgroundColor: theme.colors.transparent,
    },
  ] as StyleProp<ViewStyle>,

  reversed: [
    $baseViewStyle,
    { backgroundColor: palette.neutral800 },
  ] as StyleProp<ViewStyle>,

  light: [
    $baseViewStyle,
    {
      backgroundColor: palette.neutral800 + "AA",
      borderColor: theme.colors.transparent,
    },
  ] as StyleProp<ViewStyle>,
});

const $textPresets: Record<Presets, StyleProp<TextStyle>> = {
  default: [$baseTextStyle, { color: palette.neutral900 }],
  rounded: [$baseTextStyle, { color: palette.red }],
  filled: [$baseTextStyle, { color: palette.neutral900 }],
  outlined: $baseTextStyle,
  reversed: [$baseTextStyle, { color: palette.neutral100 }],
  light: [$baseTextStyle, { color: palette.neutral100 }],
};

const $pressedViewPresets = (
  _theme: BrandTheme,
): Record<Presets, StyleProp<ViewStyle>> => ({
  default: { backgroundColor: palette.neutral200 },
  rounded: { backgroundColor: palette.red },
  filled: { backgroundColor: palette.neutral400 },
  outlined: { backgroundColor: palette.neutral200 },
  reversed: { backgroundColor: palette.neutral700 },
  light: { backgroundColor: palette.neutral700 },
});

const $pressedTextPresets: Record<Presets, StyleProp<TextStyle>> = {
  default: { opacity: 0.9 },
  rounded: { opacity: 0.9, color: palette.neutral100 },
  filled: { opacity: 0.9 },
  outlined: { opacity: 0.9 },
  reversed: { opacity: 0.9 },
  light: { opacity: 0.9 },
};
