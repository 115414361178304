import React from "react";
import { View } from "react-native";
import { spacing } from "../../theme";
import { ABILITIES, numberToAbility } from "../../utils/numberToAbility";
import { CardToggle } from "../CardToggle";

export function AbilitySlider({
  onValueChange,
  value,
}: {
  onValueChange: (number: number) => void;
  value: number;
}) {
  return (
    <View style={{ gap: spacing.small }}>
      {ABILITIES.map((ability, index) => (
        <CardToggle
          key={index}
          title={numberToAbility(ability)}
          value={numberToAbility(value) === numberToAbility(ability)}
          onToggle={() => {
            onValueChange(ability);
          }}
        />
      ))}
    </View>
  );
}
