import { DistanceSize } from "./DistanceSize";
import {
  COEF_OF_KM_TO_MILE,
  METER_IN_YARD,
  NB_OF_YARD_IN_A_MILE,
} from "./constants";

export class Speed {
  private readonly value: number;
  private readonly effort: number;
  private readonly distanceSize: DistanceSize;
  private readonly isMetricSystem: boolean;

  protected constructor(
    valueAsSmallSize: number,
    effort: number,
    size: DistanceSize,
    isMetricSystem: boolean,
  ) {
    this.value = valueAsSmallSize;
    this.effort = effort;
    this.distanceSize = size;
    this.isMetricSystem = isMetricSystem;
  }

  static of(
    valueAsSmallSize: number,
    effort = 100,
    size = DistanceSize.long,
    isMetricSystem = true,
  ): Speed {
    return new Speed(valueAsSmallSize, effort, size, isMetricSystem);
  }

  getSpeedWithEffort() {
    return this.value * (this.effort / 100);
  }

  changeSystemForLongDistance(isMetricSystem: boolean): Speed {
    const noSystemChange = isMetricSystem === this.isMetricSystem;
    if (noSystemChange) {
      return Speed.of(
        this.value,
        this.effort,
        this.distanceSize,
        this.isMetricSystem,
      );
    }

    if (isMetricSystem) {
      const value = this.value * COEF_OF_KM_TO_MILE;
      return Speed.of(
        Number(value.toFixed(5)),
        this.effort,
        this.distanceSize,
        true,
      );
    } else {
      return Speed.of(
        this.value / COEF_OF_KM_TO_MILE,
        this.effort,
        this.distanceSize,
        false,
      );
    }
  }

  changeSystemForShortDistance(isMetricSystem: boolean): Speed {
    const noSystemChange = isMetricSystem === this.isMetricSystem;
    if (noSystemChange) {
      return Speed.of(
        this.value,
        this.effort,
        this.distanceSize,
        this.isMetricSystem,
      );
    }

    if (isMetricSystem) {
      return Speed.of(
        this.value * METER_IN_YARD,
        this.effort,
        this.distanceSize,
        true,
      );
    } else {
      return Speed.of(
        this.value / METER_IN_YARD,
        this.effort,
        this.distanceSize,
        false,
      );
    }
  }

  getInAdaptedDistanceSize(): number {
    const shouldConvertMilesToYardIfImperial =
      this.distanceSize === DistanceSize.short && !this.isMetricSystem;
    if (shouldConvertMilesToYardIfImperial) {
      return (this.getSpeedWithEffort() / NB_OF_YARD_IN_A_MILE) * 1000;
    }
    return this.getSpeedWithEffort();
  }

  getValue(): number {
    return this.value;
  }

  getDistanceSize(): DistanceSize {
    return this.distanceSize;
  }

  getIsMetricSystem(): boolean {
    return this.isMetricSystem;
  }
}
