import { Instance, types } from "mobx-state-tree";
import { AssertEqual, assertType } from "../../services/are-types-equal";
import { RaceDTO } from "../../domain/race";
import { WaypointModel } from "../waypoint";
import { api } from "../../services/api";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import { FeatureCollection, Geometry } from "@turf/helpers";
import { GeoJsonSegmenter } from "../../services/GeoJsonSegmenter";
import { GeoJsonEnhancer } from "../../services/GeoJsonEnhancer";
import { Sport } from "../sport/sport";
import { Time } from "../../domain/race-calculator/Time";
import { ForecastModel } from "../forecast";
import { RaceType } from "./race-type";

export const RaceModel = types
  .model("Race")
  .props({
    id: types.identifier,
    title: types.string,
    type: types.string,
    distance: types.number,
    date: types.optional(types.string, ""),
    url: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
    textColor: types.maybeNull(types.string),
    backgroundColor: types.maybeNull(types.string),
    waypoints: types.optional(types.array(WaypointModel), []),
    forecasts: types.optional(types.array(ForecastModel), []),
    gpxUrl: types.maybeNull(types.string),
    geoJson: types.maybeNull(types.string),
    latitude: types.maybeNull(types.number),
    longitude: types.maybeNull(types.number),
    place: types.maybeNull(types.string),
    isLocal: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get geoJsonParsed(): FeatureCollection<Geometry> | null {
      if (!self.geoJson) {
        return null;
      }
      return JSON.parse(self.geoJson);
    },
  }))
  .actions((self) => ({
    updateLocal: function (race: Partial<Race>): void {
      Object.assign(self, race);
    },
    getTime: function (sport: Sport): Time | null {
      if (self.geoJson) {
        const segments = new GeoJsonSegmenter(self.geoJsonParsed!).segmentTrail(
          1000,
        );
        const enhancedSegments = new GeoJsonEnhancer(
          segments,
          [5, 10],
          self.type as RaceType,
          {
            climberAbility: sport.climberAbility,
            descenderAbility: sport.descenderAbility,
            baseSpeed: sport.domainSpeed.getSpeedWithEffort(),
            weight: sport.weight,
          },
          sport.isTimeObjective,
        ).enhanceSegments();
        return TimeConverter.fromSecondsToDomain(
          enhancedSegments[enhancedSegments.length - 1]?.cumulativeTime || 0,
        );
      }
      return null;
    },
    updateGeoJson: function (string: string) {
      self.geoJson = string;
    },
    fetchGeoJson: async function () {
      if (!self.gpxUrl || self.geoJson) {
        return;
      }

      await api.getGeoJsonFromGpx(self.gpxUrl).then((response) => {
        if (response.kind === "ok") {
          this.updateGeoJson(response.data);
        }
      });
    },
  }));

export interface Race extends Instance<typeof RaceModel> {}

assertType<
  AssertEqual<
    Omit<RaceDTO, "waypoints" | "forecasts" | "location" | "active" | "tags">,
    Omit<
      Race,
      | "updateGeoJson"
      | "fetchGeoJson"
      | "waypoints"
      | "forecasts"
      | "geoJsonParsed"
      | "getTime"
      | "updateLocal"
      | "isLocal"
      | "date"
    >
  >
>();
