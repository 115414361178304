import { palette, spacing } from "../theme";
import React, { ComponentType } from "react";
import {
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from "react-native";

export function Tag(props: {
  children: React.ReactNode;
  style?: ViewStyle;
  onPress?: () => void;
}) {
  const Wrapper = (
    props.onPress ? TouchableOpacity : View
  ) as ComponentType<TouchableOpacityProps>;

  return (
    <Wrapper
      style={{
        borderRadius: 5,
        paddingHorizontal: spacing.tiny,
        backgroundColor: palette.secondary500,
        ...props.style,
      }}
      onPress={props.onPress}
    >
      {props.children}
    </Wrapper>
  );
}
