import { View } from "react-native";
import { palette } from "../theme";
import React from "react";

export function Dashes(props: { borderColor?: string }) {
  return (
    <View style={{ height: 1, overflow: "hidden" }}>
      <View
        style={{
          height: 2,
          borderWidth: 1,
          borderColor: props.borderColor || palette.neutral100,
          borderStyle: "dashed",
        }}
      />
    </View>
  );
}
