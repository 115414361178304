import { Instance, types } from "mobx-state-tree";
import { AssertEqual, assertType } from "../services/are-types-equal";
import { ForecastDomain } from "../domain/forecast";

export const ForecastModel = types.model("Forecast").props({
  time: types.string,
  temperature: types.maybe(types.number),
  precipitation: types.maybe(types.number),
  windSpeed: types.maybe(
    types.model({
      ele10m: types.maybe(types.number),
      ele20m: types.maybe(types.number),
      ele30m: types.maybe(types.number),
      ele40m: types.maybe(types.number),
      ele50m: types.maybe(types.number),
      ele80m: types.maybe(types.number),
      ele100m: types.maybe(types.number),
    }),
  ),
  windDirection: types.maybe(
    types.model({
      ele10m: types.maybe(types.number),
      ele20m: types.maybe(types.number),
      ele30m: types.maybe(types.number),
      ele40m: types.maybe(types.number),
      ele50m: types.maybe(types.number),
      ele80m: types.maybe(types.number),
      ele100m: types.maybe(types.number),
    }),
  ),
});

export interface Forecast extends Instance<typeof ForecastModel> {}

assertType<AssertEqual<ForecastDomain, Forecast>>();
