import { observer } from "mobx-react-lite";
import { useStores } from "../../models";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { View } from "react-native";
import { BACKGROUND_CONTAINER } from "../../theme/view-style";
import { spacing } from "../../theme";
import { ListRaces } from "./ListRaces";
import { translate } from "swunitch-i18n";
import { NearByRaces } from "./NearByRaces";
import React, { FC, useEffect, useState } from "react";
import { CompositeScreenProps } from "@react-navigation/native";
import { Category } from "../../domain/category";
import { CategoriesComponent } from "./CategoriesComponent";
import { Screen } from "../../components";
import { AppStackScreenProps, AppTabScreenProps } from "../../navigators";
import { useOnboarding } from "../../components/OnboardingContext";

export const HomeScreen: FC<
  CompositeScreenProps<AppTabScreenProps<"home">, AppStackScreenProps<"app">>
> = observer(function HomeScreen() {
  const { globalStore } = useStores();
  const theme = useBrandTheme();
  const [categories, setCategories] = useState<Category[]>([]);
  const { launch } = useOnboarding();

  useEffect(() => {
    if (globalStore.shouldRunTour()) {
      launch();
    }
  }, [globalStore.hasRunTour]);

  useEffect(() => {
    globalStore.search.reset();
    globalStore.fetchRaces();

    globalStore.getCategories().then((categories) => {
      setCategories(categories);
    });
  }, []);

  return (
    <Screen preset="scroll">
      <View
        style={{
          ...BACKGROUND_CONTAINER(theme),
          paddingTop: spacing.medium,
          gap: spacing.large,
        }}
      >
        <ListRaces
          title={translate("runScreen.favorites")}
          races={globalStore.favoritedRaces}
          computeTime={true}
          carousel={true}
          isFavorites={true}
        />

        <CategoriesComponent categories={categories} />

        <NearByRaces />

        <ListRaces
          title={translate("runScreen.races")}
          races={
            globalStore.searchedRaces.length && globalStore.search.nbOfFilters
              ? globalStore.searchedRaces
              : globalStore.races
          }
          computeTime={false}
          isReminder={true}
          displayFilters={true}
        />
      </View>
    </Screen>
  );
});
