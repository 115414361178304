// TODO: write documentation for colors and palette in own markdown file and add links from here

import { DarkTheme } from "@react-navigation/native";
import { palette } from "./palette";

const colors = {
  /**
   * The palette is available to use, but prefer using the name.
   * This is only included for rare, one-off cases. Try to use
   * semantic names as much as possible.
   */
  palette,
  /**
   * A helper for making something see-thru.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The default text color in many components.
   */
  text: palette.neutral100,
  /**
   * Secondary text information.
   */
  textDim: palette.neutral500,
  /**
   * The default color of the screen background.
   */
  backgroundApp: palette.neutral900,
  backgroundPrimary: palette.neutral900,
  backgroundInput: palette.neutral700,
  /**
   * The default border color.
   */
  border: palette.neutral700,
  /**
   * The main tinting color.
   */
  tint: palette.primary500,
  /**
   * A subtle color used for lines.
   */
  separator: palette.neutral600,
  /**
   * Error messages.
   */
  error: palette.angry500,
  /**
   * Error Background.
   *
   */
  errorBackground: palette.angry100,

  primaryDarker: palette.redDarker,
  primaryLight: palette.redLight,
  primary: palette.red,
  input: palette.offWhite,
};

export const AppDarkTheme = {
  ...DarkTheme,
  dark: true,
  colors: {
    ...DarkTheme.colors,
    ...colors,
    background: "transparent",
  },
};
