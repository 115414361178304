import { observer } from "mobx-react-lite";
import { spacing } from "../../theme";
import { ImageBackground, ScrollView, View } from "react-native";
import { Button, Card, Text } from "../../components";
import React from "react";
import { Category } from "../../domain/category";
import { useOrientation } from "../../utils/orientation-style";
import {
  CONTAINER,
  SMALL_BUTTON,
  SMALL_BUTTON_TEXT,
} from "../../theme/view-style";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigators";
import { useBrandTheme } from "../../theme/use-brand-theme";
import Config from "../../config";
import { useStores } from "../../models";
import { SEE_BOTTOM_SHADOW } from "./ListRaces";

export const CategoriesComponent = observer(
  function CategoriesComponent(props: { categories: Category[] }) {
    const { categories } = props;
    const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
    const deviceOrientation = useOrientation();
    const theme = useBrandTheme();
    const { globalStore } = useStores();

    const goToCategory = (category: Category) => {
      globalStore.search.reset();
      globalStore.search.updateSearch(category.search);
      navigation.navigate("races");
    };

    return categories.length === 0 ? null : (
      <View>
        <View style={CONTAINER(deviceOrientation)}>
          <Text preset="heading" tx="runScreen.categories" />
        </View>

        <ScrollView
          style={SEE_BOTTOM_SHADOW}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
        >
          {categories.map((category, index) => (
            <Card
              key={index}
              style={{
                width: 170,
                height: 170,
              }}
              onPress={() => goToCategory(category)}
            >
              <ImageBackground
                source={{
                  uri: `${Config.BASE_URL}/images/categories/${category.name}.jpg`,
                }}
                style={{
                  justifyContent: "flex-end",
                  width: 170,
                  height: 170,
                }}
              >
                <Button
                  preset="reversed"
                  style={{
                    ...SMALL_BUTTON,
                    backgroundColor: theme.colors.primary,
                    alignSelf: "flex-start",
                    margin: spacing.extraSmall,
                  }}
                  textStyle={SMALL_BUTTON_TEXT}
                  tx={category.name}
                  onPress={() => goToCategory(category)}
                />
              </ImageBackground>
            </Card>
          ))}
        </ScrollView>
      </View>
    );
  },
);
