import React, { ReactElement } from "react";
import {
  StyleProp,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from "react-native";
import { spacing } from "../theme";
import { Icon, IconTypes } from "./Icon";
import { TextProps } from "./Text";
import { BrandTheme, useBrandTheme } from "../theme/use-brand-theme";

export interface ListItemProps extends TouchableOpacityProps {
  /**
   * How tall the list item should be.
   * Default: 56
   */
  height?: number;
  /**
   * Whether to show the top separator.
   * Default: false
   */
  topSeparator?: boolean;
  /**
   * Whether to show the bottom separator.
   * Default: false
   */
  bottomSeparator?: boolean;
  /**
   * Children components.
   */
  children?: TextProps["children"];
  /**
   * Optional text style override.
   */
  viewStyle?: StyleProp<TextStyle>;
  /**
   * Pass any additional props directly to the Text component.
   */
  ViewProps?: TextProps;
  /**
   * Optional View container style override.
   */
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Optional TouchableOpacity style override.
   */
  style?: StyleProp<ViewStyle>;
  /**
   * Icon that should appear on the left.
   */
  leftIcon?: IconTypes;
  /**
   * An optional tint color for the left icon
   */
  leftIconColor?: string;
  /**
   * Icon that should appear on the right.
   */
  rightIcon?: IconTypes;
  /**
   * An optional tint color for the right icon
   */
  rightIconColor?: string;
  /**
   * Right action custom ReactElement.
   * Overrides `rightIcon`.
   */
  RightComponent?: ReactElement;
  /**
   * Left action custom ReactElement.
   * Overrides `leftIcon`.
   */
  LeftComponent?: ReactElement;
}

interface ListItemActionProps {
  icon?: IconTypes;
  iconColor?: string;
  Component?: ReactElement;
  size: number;
  side: "left" | "right";
}

/**
 * A styled row component that can be used in FlatList, SectionList, or by itself.
 *
 * - [Documentation and Examples](https://github.com/infinitered/ignite/blob/master/docs/Components-ListItem.md)
 */
export function ListItem(props: ListItemProps) {
  const {
    bottomSeparator,
    children,
    height = 56,
    LeftComponent,
    leftIcon,
    leftIconColor,
    RightComponent,
    rightIcon,
    rightIconColor,
    style,
    ViewProps,
    topSeparator,
    viewStyle: $viewStyleOverride,
    containerStyle: $containerStyleOverride,
    ...TouchableOpacityProps
  } = props;
  const theme = useBrandTheme();

  const $textStyles = [$viewStyle, $viewStyleOverride, ViewProps?.style];

  const $containerStyles = [
    topSeparator && $separatorTop(theme),
    bottomSeparator && $separatorBottom(theme),
    $containerStyleOverride,
  ];

  const $touchableStyles = [$touchableStyle, { minHeight: height }, style];

  return (
    <View style={$containerStyles}>
      <TouchableOpacity {...TouchableOpacityProps} style={$touchableStyles}>
        <ListItemAction
          side="left"
          size={height}
          icon={leftIcon}
          iconColor={leftIconColor}
          Component={LeftComponent}
        />

        <View {...ViewProps} style={$textStyles}>
          {children}
        </View>

        <ListItemAction
          side="right"
          size={height}
          icon={rightIcon}
          iconColor={rightIconColor}
          Component={RightComponent}
        />
      </TouchableOpacity>
    </View>
  );
}

function ListItemAction(props: ListItemActionProps) {
  const { icon, Component, iconColor, size, side } = props;

  const $iconContainerStyles = [$iconContainer];

  if (Component) return Component;

  if (icon) {
    return (
      <Icon
        size={24}
        icon={icon}
        color={iconColor}
        containerStyle={[
          $iconContainerStyles,
          side === "left" && $iconContainerLeft,
          side === "right" && $iconContainerRight,
          { height: size },
        ]}
      />
    );
  }

  return null;
}

const $separatorTop = (theme: BrandTheme): ViewStyle => ({
  borderTopWidth: 1,
  borderTopColor: theme.colors.separator,
});

const $separatorBottom = (theme: BrandTheme): ViewStyle => ({
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.separator,
});

const $viewStyle: ViewStyle = {
  paddingVertical: spacing.extraSmall,
  alignSelf: "center",
  flexGrow: 1,
  flexShrink: 1,
};

const $touchableStyle: ViewStyle = {
  flexDirection: "row",
  alignItems: "flex-start",
};

const $iconContainer: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 0,
};
const $iconContainerLeft: ViewStyle = {
  marginEnd: spacing.medium,
};

const $iconContainerRight: ViewStyle = {
  marginStart: spacing.medium,
};
