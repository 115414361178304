// TODO: write documentation about fonts and typography along with guides on how to add custom fonts in own
// markdown file and add links from here

import { Platform } from "react-native";
import {
  Roboto_400Regular as robotoNormal,
  Roboto_500Medium as robotoMedium,
  Roboto_700Bold as robotoBold,
} from "@expo-google-fonts/roboto";

export const customFontsToLoad = {
  ChillaxSemibold: require("../../assets/fonts/ChillaxSemibold.otf"),
  ChillaxMedium: require("../../assets/fonts/ChillaxMedium.otf"),
  ChillaxBold: require("../../assets/fonts/ChillaxBold.otf"),
  robotoNormal,
  robotoMedium,
  robotoBold,
};

const fonts = {
  roboto: {
    normal: "robotoNormal",
    medium: "robotoMedium",
    bold: "robotoBold",
  },
  chillax: {
    normal: "ChillaxMedium",
    semiBold: "ChillaxSemibold",
    bold: "ChillaxBold",
  },
  helveticaNeue: {
    // iOS only font.
    thin: "HelveticaNeue-Thin",
    light: "HelveticaNeue-Light",
    normal: "Helvetica Neue",
    medium: "HelveticaNeue-Medium",
  },
  courier: {
    // iOS only font.
    normal: "Courier",
  },
  sansSerif: {
    // Android only font.
    thin: "sans-serif-thin",
    light: "sans-serif-light",
    normal: "sans-serif",
    medium: "sans-serif-medium",
  },
  monospace: {
    // Android only font.
    normal: "monospace",
  },
};

export const typography = {
  /**
   * The fonts are available to use, but prefer using the semantic name.
   */
  fonts,
  title: fonts.chillax,
  /**
   * The primary font. Used in most places.
   */
  primary: fonts.roboto,
  /**
   * An alternate font used for perhaps titles and stuff.
   */
  secondary: Platform.select({
    web: fonts.helveticaNeue,
    ios: fonts.helveticaNeue,
    android: fonts.sansSerif,
  })!,
  /**
   * Lets get fancy with a monospace font!
   */
  code: Platform.select({ ios: fonts.courier, android: fonts.monospace }),
};
