import { Time } from "./Time";
import { translate } from "swunitch-i18n";

export class TimeConverter {
  static fromSecondsToDomain(totalSeconds: number): Time {
    const days = Math.floor(totalSeconds / 60 / 60 / 24);
    const hours = Math.floor((totalSeconds / 60 / 60) % 24);
    const minutes = Math.floor((totalSeconds / 60) % 60);
    const seconds = Math.floor(totalSeconds % 60);

    return new Time({
      days,
      hours,
      minutes,
      seconds,
    });
  }

  static toDomain(distanceInMeter: number, speed: number): Time {
    let totalSeconds = (distanceInMeter / speed) * 60 * 60;
    if (!isFinite(totalSeconds)) {
      totalSeconds = 0;
    }

    return this.fromSecondsToDomain(Number(totalSeconds.toFixed(5)));
  }

  static toHuman(time: Time, short = false): string {
    if (time.days.value) {
      return (
        `${time.days.value}${short ? ":" : translate("units.day") + " "}` +
        `${time.hours.format()}${short ? ":" : "h "}` +
        `${time.minutes.format()}${short ? ":" : "m "}` +
        `${time.seconds.format()}${short ? "" : "s"}`
      );
    }
    if (time.hours.value) {
      return (
        `${time.hours.format()}${short ? ":" : "h "}` +
        `${time.minutes.format()}${short ? ":" : "m "}` +
        `${time.seconds.format()}${short ? "" : "s"}`
      );
    }
    if (time.minutes.value) {
      return (
        `${time.minutes.format()}${short ? ":" : "m "}` +
        `${time.seconds.format()}${short ? "" : "s"}`
      );
    }

    return time.seconds.format() + (short ? "" : "s");
  }

  static toSeconds(time: Time): number {
    return (
      time.days.value * 3600 * 24 +
      time.hours.value * 3600 +
      time.minutes.value * 60 +
      time.seconds.value
    );
  }
}
