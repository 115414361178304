export class MinPerHundredMeter {
  static fromMeterPerHour(meterPerHour: number): string {
    const totalSeconds = 60 * 60;

    const _100_METERS = 100;
    const paceInSeconds =
      meterPerHour === 0 ? 0 : (totalSeconds / meterPerHour) * _100_METERS;
    const minutes = Math.floor(paceInSeconds / 60);
    const seconds = Math.round(paceInSeconds - minutes * 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  }

  static toMeterPerHour(pace: string): number {
    const [min, sec] = pace.padEnd(4, "0").split(":").map(Number);
    const secAsMin = sec / 60;
    const timeFor1Km = (min + secAsMin) * 10;
    const kmPerMin = 1 / timeFor1Km;

    if (!isFinite(kmPerMin)) {
      return 0;
    }

    const meterPerMin = kmPerMin * 1000;
    return meterPerMin * 60;
  }
}
