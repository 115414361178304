import { Instance, types } from "mobx-state-tree";
import { DistanceSize } from "../../domain/race-calculator/DistanceSize";
import { getRootStore } from "../helpers/getRootStore";
import { Distance } from "../../domain/race-calculator/Distance";

export const DistanceModel = types
  .model("Distance")
  .props({
    id: types.identifier,
    value: types.number,
    distanceSize: types.enumeration<DistanceSize>(Object.values(DistanceSize)),
  })
  .views((self) => ({
    get domainDistance(): Distance {
      const root = getRootStore(self);

      return Distance.of(
        self.value,
        self.distanceSize,
        root.globalStore.isMetricSystem,
      );
    },
  }))
  .actions((self) => ({
    updateValue: function (distance: Distance) {
      self.value = distance.value;
      self.distanceSize = distance.distanceSize;
    },
  }));

export interface DistanceModelInterface
  extends Instance<typeof DistanceModel> {}
