import { EnhancedSegment } from "./GeoJsonEnhancer";
import togpx from "togpx";

export class GpxExporter {
  public static toGpx(title: string, segments: EnhancedSegment[]): string {
    const startDate = new Date(new Date().toISOString());

    const coordinates = segments.map((segment) => segment.coordinates);
    const coordTimes = segments.map(
      (segment) =>
        new Date(startDate.getTime() + segment.cumulativeTime * 1000),
    );

    const geoJson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: coordinates,
          },
          properties: {
            name: `⌛ ${title}`,
            time: startDate.toISOString(),
            coordTimes: coordTimes,
          },
        },
      ],
    };

    return togpx(geoJson, {
      creator: "Pacevisor",
      metadata: {
        name: `⌛ ${title}`,
        time: coordTimes[0],
      },
      featureCoordTimes: (props) => props.properties.coordTimes,
    });
  }
}
