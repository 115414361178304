import React, { createContext, RefObject, useContext, useRef } from "react";
import { ScrollView } from "react-native";

const ScreenContext = createContext<{
  ref: RefObject<ScrollView>;
  scrollToTop: () => void;
}>({
  ref: { current: null },
  scrollToTop: () => null,
});

export const ScreenProvider = ({ children }) => {
  const scrollViewRef = useRef<ScrollView>(null);

  const scrollToTop = () => {
    if (scrollViewRef && scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y: 0, animated: true });
    }
  };

  return (
    <ScreenContext.Provider
      value={{
        ref: scrollViewRef,
        scrollToTop,
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreen = () => {
  return useContext(ScreenContext);
};
