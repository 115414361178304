export class Vma {
  static getPercentFromDistance(vma: number, distance: number) {
    const time = distance / vma;
    const timeInMinutes = time * 60;

    let percentVMA: number;

    if (timeInMinutes <= 6) {
      percentVMA = 100;
    } else if (timeInMinutes <= 20) {
      percentVMA = 100 - 0.5 * (timeInMinutes - 6);
    } else if (timeInMinutes <= 90) {
      percentVMA = 95 - 0.3 * (timeInMinutes - 20);
    } else if (timeInMinutes <= 300) {
      percentVMA = 85 - 0.2 * (timeInMinutes - 90);
    } else {
      percentVMA = 70 - 0.1 * (timeInMinutes - 300);
    }

    if (percentVMA > 100) {
      percentVMA = 100;
    } else if (percentVMA < 30) {
      percentVMA = 30;
    }

    return percentVMA;
  }
}
