import { observer } from "mobx-react-lite";
import { RaceType } from "../../models/race/race-type";
import { Timeline } from "./triathlon/Timeline";
import React, { useMemo, useState } from "react";
import { Sport } from "../../models/sport/sport";
import { useStores } from "../../models";
import { palette, spacing } from "../../theme";
import { TouchableOpacity, View, ViewStyle } from "react-native";
import { MinPerKilometerComponent } from "../bottom-sheet/MinPerKilometerComponent";
import { KilometerPerHourComponent } from "../bottom-sheet/KilometerPerHourComponent";
import Accordion from "react-native-collapsible/Accordion";
import { Text } from "../Text";
import { ObjectiveModeSegmented } from "../segmented/ObjectiveModeSegmented";
import { EffortComponent } from "../bottom-sheet/EffortComponent";
import { MinPerHundredMeterComponent } from "../bottom-sheet/MinPerHundredMeterComponent";
import { Card } from "../Card";
import {
  BOTTOM_SHEET_TEXT,
  CENTER_MIDDLE,
  CONTAINER,
} from "../../theme/view-style";
import { useOrientation } from "../../utils/orientation-style";
import { AbilitySlider } from "../race-analysis/AbilitySlider";
import { TextField } from "../TextField";
import { translate } from "swunitch-i18n";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { numberToAbility } from "../../utils/numberToAbility";
import { AppLightTheme } from "../../theme/colors-light";

const HEADER_STYLE: ViewStyle = {
  marginVertical: spacing.small,
};

export const SportsComponent = observer(function SportsComponent({
  type,
  setType,
  hideMoreOptions,
}: {
  type: RaceType | "all";
  setType: (type: RaceType | "all") => void;
  hideMoreOptions: boolean;
}) {
  const { globalStore } = useStores();
  const [activeSections, setActiveSections] = useState<number[]>([]);
  const [activeSubSections, setActiveSubSections] = useState<number[]>([]);
  const deviceOrientation = useOrientation();
  const theme = useBrandTheme();
  const sport: Sport = useMemo(() => {
    if (type === "all") {
      return globalStore.getSportByRaceType(RaceType.Run);
    }
    return globalStore.getSportByRaceType(type);
  }, [type]);

  function updateWeight(text: string) {
    const w = Number(text);
    sport.updateWeight(w);
  }

  const CONTENT: {
    title: string;
    value: string;
    content: React.ReactNode;
  }[] = [
    {
      title: translate("units.effort"),
      value: sport.effort.toString(),
      content: (
        <EffortComponent
          effort={sport.effort}
          onEffortChange={(e) => sport.updateEffort(e)}
          textColor={AppLightTheme.colors.text}
        />
      ),
    },
    {
      title: translate("raceScreen.predict.mode"),
      value: sport.isTimeObjective
        ? translate("segmented.timeObjective")
        : translate("segmented.distanceObjective"),
      content: <ObjectiveModeSegmented sport={sport} />,
    },
    ...(type === RaceType.Bike
      ? [
          {
            title: translate("raceScreen.weight"),
            value: sport.weight.toString(),
            content: (
              <TextField
                onChangeText={updateWeight}
                defaultValue={sport.weight.toString()}
                keyboardType="numeric"
                style={BOTTOM_SHEET_TEXT}
              />
            ),
          },
        ]
      : []),
    {
      title: translate("raceScreen.climberNote"),
      value: numberToAbility(sport.climberAbility),
      content: (
        <AbilitySlider
          onValueChange={(v) => sport.updateClimberAbility(v)}
          value={sport.climberAbility}
        />
      ),
    },
    {
      title: translate("raceScreen.descenderNote"),
      value: numberToAbility(sport.descenderAbility),
      content: (
        <AbilitySlider
          onValueChange={(v) => sport.updateDescenderAbility(v)}
          value={sport.descenderAbility}
        />
      ),
    },
  ];

  return (
    <View style={{ gap: spacing.medium }}>
      {type !== "all" && (
        <>
          <View
            style={[
              CONTAINER(deviceOrientation),
              {
                flexDirection: "row",
                justifyContent: "space-between",
              },
            ]}
          >
            {type === RaceType.Run ? (
              <>
                <MinPerKilometerComponent sport={sport} />

                <KilometerPerHourComponent sport={sport} />
              </>
            ) : null}
            {type === RaceType.Bike ? (
              <KilometerPerHourComponent sport={sport} />
            ) : null}
            {type === RaceType.Swim ? (
              <MinPerHundredMeterComponent sport={sport} />
            ) : null}
          </View>

          {hideMoreOptions ? null : (
            <Card
              noShadow
              style={{
                marginHorizontal: spacing.tiny,
                backgroundColor: palette.neutral200,
              }}
            >
              <Accordion
                activeSections={activeSections}
                sections={[
                  {
                    title: translate("raceScreen.predict.advanced"),
                    icon: (
                      <MaterialCommunityIcons
                        name="chevron-down"
                        size={24}
                        color={palette.secondary500}
                      />
                    ),
                    content: (
                      <Card
                        noShadow
                        style={{
                          ...CONTAINER(deviceOrientation),
                          marginHorizontal: spacing.tiny,
                          marginBottom: spacing.tiny,
                          backgroundColor: palette.neutral100,
                        }}
                      >
                        <Accordion
                          activeSections={activeSubSections}
                          sections={CONTENT}
                          touchableComponent={TouchableOpacity}
                          expandMultiple={false}
                          renderHeader={(section, _, _isActive) => (
                            <View
                              style={[
                                HEADER_STYLE,
                                CENTER_MIDDLE,
                                { justifyContent: "space-between" },
                              ]}
                            >
                              <Text
                                preset="formLabel"
                                style={{
                                  color: AppLightTheme.colors.text,
                                }}
                              >
                                {section.title}
                              </Text>
                              <Text
                                style={{
                                  color: AppLightTheme.colors.text,
                                }}
                              >
                                {section.value}
                              </Text>
                            </View>
                          )}
                          renderContent={(section, _, _isActive) => (
                            <View
                              style={{
                                marginVertical: spacing.small,
                              }}
                            >
                              {section.content}
                            </View>
                          )}
                          renderFooter={() => (
                            <View
                              style={{
                                borderBottomWidth: 1,
                                borderBottomColor: theme.colors.textDim,
                              }}
                            />
                          )}
                          onChange={setActiveSubSections}
                          renderAsFlatList={false}
                        />
                      </Card>
                    ),
                  },
                ]}
                touchableComponent={TouchableOpacity}
                expandMultiple={false}
                renderHeader={(section, _, _isActive) => (
                  <View
                    style={[
                      HEADER_STYLE,
                      CONTAINER(deviceOrientation),
                      CENTER_MIDDLE,
                      { justifyContent: "space-between" },
                    ]}
                  >
                    <Text
                      preset="subheading"
                      style={{
                        color: palette.secondary500,
                      }}
                    >
                      {section.title}
                    </Text>
                    {section.icon}
                  </View>
                )}
                renderContent={(section, _, _isActive) => (
                  <View>{section.content}</View>
                )}
                onChange={setActiveSections}
                renderAsFlatList={false}
              />
            </Card>
          )}

          {hideMoreOptions ? (
            <View style={CONTAINER(deviceOrientation)}>
              <EffortComponent
                effort={sport.effort}
                onEffortChange={(e) => sport.updateEffort(e)}
              />
            </View>
          ) : null}
        </>
      )}
      {type === "all" && (
        <View style={CONTAINER(deviceOrientation)}>
          <Timeline onChangeType={(t) => setType(t)} />
        </View>
      )}
    </View>
  );
});
