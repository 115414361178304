import React, { useCallback, useMemo, useState } from "react";
import { Platform, useWindowDimensions, View, ViewStyle } from "react-native";
import { observer } from "mobx-react-lite";
import { spacing } from "../../theme";
import { EnhancedSegment } from "../../services/GeoJsonEnhancer";
import { Race } from "../../models/race/race";
import * as shape from "d3-shape";
import { KilometerPerHour } from "../../domain/speed-converters/KilometerPerHour";
import { MinPerKilometer } from "../../domain/speed-converters/MinPerKilometer";
import { formatNumber } from "../../utils/formatNumber";
import { Speed } from "../../domain/race-calculator/Speed";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { LineChart } from "react-native-wagmi-charts";
import { Foundation, MaterialCommunityIcons } from "@expo/vector-icons";
import { Text } from "../Text";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import * as Haptics from "expo-haptics";
import { translate } from "swunitch-i18n";
import { CARD_MARGIN, CENTER_MIDDLE } from "../../theme/view-style";
import { Stop } from "react-native-svg";
import { getPaceUnit, getSpeedUnit } from "../../utils/humanUnit";
import { useStores } from "../../models";
import { defaultSegment } from "./defaultSegment";
import { RaceGraphCard } from "./RaceGraphCard";
import { RaceChart } from "./RaceChart";
import { getPercentColor } from "../../services/getPercentColor";
import { RaceType } from "../../models/race/race-type";
import { debounce } from "lodash";

interface RaceAnalysisProps {
  race: Race;
  profile: EnhancedSegment[];
  segments: EnhancedSegment[];
  onSegmentSelected: (segment: EnhancedSegment) => void;
  isLargeScreen: boolean;
}

export const CHART_CONFIG = {
  graphHeight: 180,
  chartPadding: {
    left: 40,
    bottom: 20,
    right: 20,
    top: 10,
  },
  lineStyle: {
    paddingLeft: 40,
    marginTop: -5,
    position: "absolute",
  } as ViewStyle,
};

export const RaceGraphs = observer(function RaceGraphs(
  props: RaceAnalysisProps,
) {
  const { profile, race, segments, onSegmentSelected, isLargeScreen } = props;
  const theme = useBrandTheme();
  const { globalStore } = useStores();
  const [isFullscreen, setFullscreen] = useState(false);

  const fullscreenWidth = isFullscreen ? 1500 : 0;

  const toggleFullscreen = useCallback(() => {
    setFullscreen(!isFullscreen);
  }, [isFullscreen]);

  const [selectedSegment, setSelectedSegment] =
    useState<EnhancedSegment>(defaultSegment);
  const lowestSpeed = useMemo(
    () => Math.max(...profile.map((segment) => segment.speed)),
    [profile],
  );
  const highestSpeed = useMemo(
    () => Math.min(...profile.map((segment) => segment.speed)),
    [profile],
  );

  const onIndexChange = useCallback(
    debounce((index: { index: number; list: "profile" | "segments" }) => {
      const s = getSelectedSegment(index);
      setSelectedSegment(s);
      onSegmentSelected(s);
    }, 10),
    [profile, segments],
  );

  const getSelectedSegment = useCallback(
    (index: { index: number; list: "profile" | "segments" }) => {
      return (
        (index.list === "profile"
          ? profile[index.index]
          : segments[index.index]) || defaultSegment
      );
    },
    [profile, segments],
  );

  const getSpeedForRaceType = useCallback(
    (speed: number): string => {
      if (race.type === RaceType.Bike) {
        return formatNumber(KilometerPerHour.fromMeterPerHour(speed));
      }

      return MinPerKilometer.fromMeterPerHour(speed);
    },
    [race.type],
  );

  const metrics = useMemo(() => {
    return [
      [
        {
          icon: (
            <MaterialCommunityIcons
              name="clock-outline"
              size={15}
              color={race.backgroundColor || theme.colors.text}
            />
          ),
          text: TimeConverter.toHuman(
            TimeConverter.fromSecondsToDomain(selectedSegment.cumulativeTime),
          ),
        },
        {
          icon: (
            <MaterialCommunityIcons
              name="arrow-left-right"
              size={15}
              color={race.backgroundColor || theme.colors.text}
            />
          ),
          text: translate("raceScreen.totalDistance", {
            distance: formatNumber(
              KilometerPerHour.fromMeterPerHour(selectedSegment.distance),
            ),
          }),
        },
        race.type === RaceType.Bike
          ? {
              icon: (
                <MaterialCommunityIcons
                  name="speedometer"
                  size={15}
                  color={race.backgroundColor || theme.colors.text}
                />
              ),
              text: translate("raceScreen.pace", {
                value: formatNumber(
                  KilometerPerHour.fromMeterPerHour(selectedSegment.speed),
                ),
                unit: getSpeedUnit(globalStore.isMetricSystem),
              }),
            }
          : {
              icon: (
                <MaterialCommunityIcons
                  name="speedometer-medium"
                  size={15}
                  color={race.backgroundColor || theme.colors.text}
                />
              ),
              text: translate("raceScreen.pace", {
                value: MinPerKilometer.fromMeterPerHour(
                  Speed.of(selectedSegment.speed).getSpeedWithEffort(),
                ),
                unit: getPaceUnit(globalStore.isMetricSystem),
              }),
            },
      ],
      [
        {
          icon: (
            <Foundation
              name="mountains"
              size={15}
              color={race.backgroundColor || theme.colors.text}
            />
          ),
          text: translate("raceScreen.elevation", {
            value: formatNumber(selectedSegment.altitude),
          }),
        },
        {
          icon: (
            <MaterialCommunityIcons
              name="arrow-top-right"
              size={15}
              color={race.backgroundColor || theme.colors.text}
            />
          ),
          text: translate("raceScreen.elevation", {
            value: formatNumber(selectedSegment.elevationGain),
          }),
        },
        {
          text: formatNumber(selectedSegment.percentAngle) + "%",
          icon: (
            <MaterialCommunityIcons
              name="angle-acute"
              size={15}
              color={race.backgroundColor || theme.colors.text}
            />
          ),
        },
      ],
    ].map((list, index) => (
      <View
        key={index}
        style={{
          flexDirection: "row",
          gap: spacing.small,
          rowGap: 0,
          flexWrap: "wrap",
        }}
      >
        {list.map((item, index) => (
          <View
            style={{
              ...CENTER_MIDDLE,
              gap: spacing.micro,
            }}
            key={index}
          >
            {item.icon}
            <Text
              preset="bold"
              size="xxs"
              style={{
                color: race.backgroundColor || theme.colors.text,
              }}
            >
              {item.text}
            </Text>
          </View>
        ))}
      </View>
    ));
  }, [race, selectedSegment, globalStore.isMetricSystem, theme.dark]);

  return (
    <View
      style={{
        flexDirection: isLargeScreen ? "row" : "column",
        justifyContent: "space-between",
      }}
    >
      <View style={isLargeScreen ? { flex: 1 } : {}}>
        <RaceGraphCard
          title={translate("raceScreen.profile")}
          subtitle={translate("raceScreen.graphSubtitle")}
          isFullscreen={isFullscreen}
          toggleFullscreen={toggleFullscreen}
          metrics={metrics}
        >
          <RaceChart
            data={profile.map((segment) => ({
              x: segment.distance,
              y: segment.altitude,
            }))}
            isLargeScreen={isLargeScreen}
            isFullscreen={isFullscreen}
            fullscreenWidth={fullscreenWidth}
            verticalAxisFormatter={(v) => v.toFixed(1)}
            yDomain={{
              min: Math.min(...profile.map((segment) => segment.altitude)),
              max: Math.max(...profile.map((segment) => segment.altitude)) + 10,
            }}
            tickCount={6}
            lineChart={
              profile.length ? (
                <View style={CHART_CONFIG.lineStyle}>
                  <LineChart.Provider
                    data={profile.map((segment, index) => ({
                      timestamp: index,
                      value: segment.altitude,
                    }))}
                    onCurrentIndexChange={(index) => {
                      if (Platform.OS !== "web") {
                        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                      }
                      onIndexChange({
                        index,
                        list: "profile",
                      });
                    }}
                    yRange={{
                      min: Math.min(
                        ...profile.map((segment) => segment.altitude),
                      ),
                      max:
                        Math.max(
                          ...profile.map((segment) => segment.altitude),
                        ) + 10,
                    }}
                  >
                    <PacevisorLineChart
                      graph="profile"
                      fullscreenWidth={fullscreenWidth}
                      {...props}
                    />
                  </LineChart.Provider>
                </View>
              ) : null
            }
          />
        </RaceGraphCard>
      </View>

      <View style={isLargeScreen ? { flex: 1 } : {}}>
        <RaceGraphCard
          title={translate("raceScreen.paceTitle")}
          subtitle={translate("raceScreen.graphSubtitle")}
          isFullscreen={isFullscreen}
          toggleFullscreen={toggleFullscreen}
          metrics={metrics}
        >
          <RaceChart
            data={segments.map((segment) => ({
              x: segment.distance,
              y: segment.speed,
            }))}
            isLargeScreen={isLargeScreen}
            isFullscreen={isFullscreen}
            fullscreenWidth={fullscreenWidth}
            verticalAxisFormatter={(v) =>
              getSpeedForRaceType(Speed.of(v).getSpeedWithEffort())
            }
            yDomain={{ min: highestSpeed - 100, max: lowestSpeed + 100 }}
            tickCount={5}
            lineChart={
              segments.length ? (
                <View style={CHART_CONFIG.lineStyle}>
                  <LineChart.Provider
                    data={segments.map((segment, index) => ({
                      timestamp: index,
                      value: segment.speed,
                    }))}
                    onCurrentIndexChange={(index) => {
                      if (Platform.OS !== "web") {
                        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                      }
                      onIndexChange({
                        index,
                        list: "segments",
                      });
                    }}
                    yRange={{ min: highestSpeed - 100, max: lowestSpeed + 100 }}
                  >
                    <PacevisorLineChart
                      graph="segments"
                      fullscreenWidth={fullscreenWidth}
                      {...props}
                    />
                  </LineChart.Provider>
                </View>
              ) : null
            }
          />
        </RaceGraphCard>
      </View>
    </View>
  );
});

function PacevisorLineChart({
  graph,
  profile,
  race,
  isLargeScreen,
  fullscreenWidth,
}: {
  graph: "profile" | "segments";
  fullscreenWidth: number;
} & RaceAnalysisProps) {
  const theme = useBrandTheme();
  const layout = useWindowDimensions();
  const widgetWidth = isLargeScreen ? layout.width / 2 : layout.width;

  const specific =
    graph === "profile" ? (
      <>
        {profile.map((segment, index) => {
          const color = getPercentColor(segment.percentAngle);
          if (color) {
            return (
              <LineChart.Highlight
                from={index}
                to={index + 1}
                key={index}
                color={color}
                width={2}
              />
            );
          }
          return null;
        })}

        {race.waypoints.map((w) => {
          const waypoint = profile.findIndex(
            (segment) => segment.distance >= w.distance,
          );
          if (waypoint === -1) {
            return null;
          }

          return (
            <LineChart.Dot
              at={waypoint}
              key={w.distance}
              color={theme.colors.primary}
            />
          );
        })}

        {
          <LineChart.Gradient
            color={race.backgroundColor || theme.colors.primary}
          >
            <Stop
              offset="10%"
              stopColor={race.backgroundColor || theme.colors.primary}
              stopOpacity={0.7}
            />
            <Stop
              offset="30%"
              stopColor={race.backgroundColor || theme.colors.primary}
              stopOpacity={0.5}
            />
            <Stop
              offset="70%"
              stopColor={race.backgroundColor || theme.colors.primary}
              stopOpacity={0.1}
            />
            <Stop
              offset="100%"
              stopColor={race.backgroundColor || theme.colors.primary}
              stopOpacity={0}
            />
          </LineChart.Gradient>
        }
      </>
    ) : null;

  return (
    <LineChart
      height={CHART_CONFIG.graphHeight}
      width={
        widgetWidth -
        CHART_CONFIG.chartPadding.left -
        CHART_CONFIG.chartPadding.right -
        CARD_MARGIN +
        fullscreenWidth
      }
      shape={shape.curveMonotoneX}
    >
      <LineChart.Path
        color={race.backgroundColor || theme.colors.primaryDarker}
        pathProps={{
          width: 2,
        }}
      >
        {specific}
      </LineChart.Path>
      <LineChart.CursorLine snapToPoint={true} minDurationMs={150} />
      <LineChart.HoverTrap />
    </LineChart>
  );
}
