// TODO: write documentation for colors and palette in own markdown file and add links from here

import { DefaultTheme } from "@react-navigation/native";
import { palette } from "./palette";

const colors = {
  /**
   * A helper for making something see-thru.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The default text color in many components.
   */
  text: palette.secondary500,
  /**
   * Secondary text information.
   */
  textDim: palette.neutral500,
  /**
   * The default color of the screen background.
   */
  backgroundApp: palette.neutral100,
  backgroundPrimary: palette.neutral100,
  backgroundInput: palette.neutral200,
  /**
   * The default border color.
   */
  border: palette.neutral300,
  /**
   * The main tinting color.
   */
  tint: palette.primary500,
  /**
   * A subtle color used for lines.
   */
  separator: palette.neutral300,
  /**
   * Error messages.
   */
  error: palette.angry500,
  /**
   * Error Background.
   *
   */
  errorBackground: palette.angry100,

  primaryDarker: palette.redDarker,
  primaryLight: palette.redLight,
  primary: palette.red,
  input: palette.offWhite,
};

export const AppLightTheme = {
  ...DefaultTheme,
  dark: false,
  colors: {
    ...DefaultTheme.colors,
    ...colors,
    background: "transparent",
  },
};
