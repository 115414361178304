import { useMemo } from "react";
import { useStores } from "../../../models";
import { Sport } from "../../../models/sport/sport";
import { TimeConverter } from "../../../domain/race-calculator/TimeConverter";
import { RaceType } from "../../../models/race/race-type";

export const useTriathlonHook = function useTriathlonHook() {
  const { globalStore } = useStores();
  const run: Sport = useMemo(
    () => globalStore.getSportByRaceType(RaceType.Run),
    [],
  );
  const bike: Sport = useMemo(
    () => globalStore.getSportByRaceType(RaceType.Bike),
    [],
  );
  const swim: Sport = useMemo(
    () => globalStore.getSportByRaceType(RaceType.Swim),
    [],
  );

  const timeForRun = useMemo(() => {
    return run.getTimeForDistance();
  }, [run.speed, run.distance.value, run.distance.distanceSize, run.effort]);
  const timeForBike = useMemo(() => {
    return bike.getTimeForDistance();
  }, [
    bike.speed,
    bike.distance.value,
    bike.distance.distanceSize,
    bike.effort,
  ]);
  const timeForBikeTransition = useMemo(() => {
    return TimeConverter.fromSecondsToDomain(bike.transitionTime);
  }, [bike.transitionTime]);
  const timeForSwim = useMemo(() => {
    return swim.getTimeForDistance();
  }, [
    swim.speed,
    swim.distance.value,
    swim.distance.distanceSize,
    swim.effort,
  ]);
  const timeForSwimTransition = useMemo(() => {
    return TimeConverter.fromSecondsToDomain(swim.transitionTime);
  }, [swim.transitionTime]);
  const fullTime = useMemo(() => {
    return timeForRun
      .add(timeForBike)
      .add(timeForBikeTransition)
      .add(timeForSwim)
      .add(timeForSwimTransition);
  }, [
    timeForRun,
    timeForBike,
    timeForSwim,
    timeForSwimTransition,
    timeForBikeTransition,
  ]);

  return {
    timeForRun,
    timeForBike,
    timeForBikeTransition,
    timeForSwim,
    timeForSwimTransition,
    fullTime,
  };
};
