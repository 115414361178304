export const palette = {
  neutral100: "#FFFFFF",
  neutral200: "#F4F2F1",
  neutral300: "#D7CEC9",
  neutral400: "#B6ACA6",
  neutral500: "#978F8A",
  neutral600: "#564E4A",
  neutral700: "#3C3836",
  neutral800: "#191015",
  neutral900: "#000000",

  primary100: "#F4E0D9",
  primary200: "#E8C1B4",
  primary300: "#DDA28E",
  primary400: "#D28468",
  primary500: "#C76542",
  primary600: "#A54F31",

  secondary100: "#12EDFF",
  secondary200: "#00CDDE",
  secondary300: "#009EAB",
  // brand
  secondary400: "#006F78",
  // brand
  secondary500: "#00373E",

  // brand
  accent100: "#FFF5E9",
  accent200: "#FFE6C7",
  accent300: "#FFD6A5",
  accent400: "#FFC783",
  accent500: "#FFB761",

  angry100: "#F2D6CD",
  angry500: "#C03403",

  overlay20: "rgba(25, 16, 21, 0.2)",
  overlay50: "rgba(25, 16, 21, 0.5)",

  // brand
  red: "#FF5900",
  redDarker: "#CC4700",
  redLight: "#FF7A33",
  offWhite: "#e6e6e6",

  green: "#008005",
};
