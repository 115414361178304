import React, { ComponentType } from "react";
import {
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from "react-native";
import { useBrandTheme } from "../theme/use-brand-theme";
import {
  CARD,
  CARD_CONTAINER,
  CARD_NO_SHADOW,
  CARD_WITH_BORDER,
} from "../theme/view-style";
import { spacing } from "../theme";

interface CardProps {
  children?: React.ReactNode;
  style?: ViewStyle;
  padding?: boolean;
  isLast?: boolean;
  onPress?: () => void;
  noShadow?: boolean;
  withBorder?: boolean;
}

export function Card(props: CardProps) {
  const { children, style, padding, isLast, noShadow, withBorder } = props;
  const theme = useBrandTheme();

  const Wrapper = (
    props.onPress ? TouchableOpacity : View
  ) as ComponentType<TouchableOpacityProps>;

  return (
    <Wrapper
      style={[
        CARD_CONTAINER(theme),
        !padding ? {} : { paddingVertical: spacing.medium },
        isLast ? { paddingBottom: 0 } : {},
        noShadow ? CARD_NO_SHADOW(theme) : {},
        withBorder ? CARD_WITH_BORDER(theme) : {},
        style,
      ]}
      onPress={props.onPress}
    >
      <View style={CARD(theme)}>{children}</View>
    </Wrapper>
  );
}
