import { Alert, Platform } from "react-native";
import { translate, TxKeyPath } from "swunitch-i18n";

export function alertMessage(
  titleTx: TxKeyPath,
  descriptionTx?: TxKeyPath,
): Promise<void> {
  return new Promise((resolve, reject) => {
    if (Platform.OS === "web") {
      const result = window.confirm(
        `${translate(titleTx)}\n${descriptionTx ? translate(descriptionTx) : ""}`,
      );
      if (result) {
        resolve();
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(null);
      }
    } else {
      Alert.alert(
        translate(titleTx),
        descriptionTx ? translate(descriptionTx) : undefined,
        [
          {
            text: translate("common.back"),
            style: "cancel",
            // eslint-disable-next-line prefer-promise-reject-errors
            onPress: () => reject(null),
          },
          {
            text: translate("common.ok"),
            onPress: () => resolve(),
          },
        ],
      );
    }
  });
}
