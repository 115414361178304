export async function recordEvent(
  urlFragment: string,
  name = "pageview",
  { host, ua, ip }: { host: string; ip?: string; ua?: string } = {
    host: "app.pacevisor.com",
  },
) {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000);
    const url = "https://" + host + "/" + urlFragment;

    await fetch("https://plausible.io/api/event", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(ua ? { "User-Agent": ua } : {}),
        ...(ip ? { "X-Forwarded-For": ip } : {}),
      },
      body: JSON.stringify({
        name,
        url,
        domain: host,
      }),
      signal: controller.signal,
    });

    clearTimeout(timeoutId);
  } catch (e) {
    console.error("recordEvent error to " + urlFragment, e);
  }
}
