import { getRoot, Instance, IStateTreeNode } from "mobx-state-tree";
import { RootStoreModel } from "../RootStore";

/**
 * Returns a RootStore object in strongly typed way
 * for stores to access other stores.
 */
export const getRootStore = (
  self: IStateTreeNode,
): Instance<typeof RootStoreModel> => {
  return getRoot<typeof RootStoreModel>(self);
};
